import { PoweroffOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthService } from "views/public/auth/services/auth.service";

const auth: AuthService = new AuthService();

const styleButton = {
	borderColor: "transparent",
	color: "white",
};

const outButton = {
	background: "#0b2540",
};

const hoverButton = {
	background: "rgba(255, 255, 255, 0.15)",
};

const menu = (
	<Menu style={{ padding: "12px", borderRadius: "1ch" }}>
		<Menu.Item key="settings">
			<Link to="/admin/settings">
				<SettingOutlined /> Settings
			</Link>
		</Menu.Item>
		<div style={{ height: "7px" }} />
		<Menu.Item key="signout">
			<Link
				to="/"
				onClick={() => {
					auth.signOut();
				}}>
				<PoweroffOutlined /> Sign Out
			</Link>
		</Menu.Item>
	</Menu>
);

/**getTime
 * Auth status and logOut function
 * @returns Render logged in status
 */

export function AuthStatus() {
	const [hover, setHover] = useState(false);

	const token = localStorage.getItem("LettriaIdToken");

	if (!token) {
		return <p>You are not logged in.</p>;
	} else {
		return (
			<Dropdown
				overlay={menu}
				placement="bottom"
				arrow
				trigger={["click"]}>
				<Button
					onMouseEnter={() => {
						setHover(true);
					}}
					onMouseLeave={() => {
						setHover(false);
					}}
					style={{
						...styleButton,
						...(hover ? hoverButton : outButton),
					}}
					shape="circle">
					<SettingOutlined style={{ fontSize: "20px" }} />
				</Button>
			</Dropdown>
		);
	}
}

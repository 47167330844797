import { List, Typography } from "antd";
import { Notification } from "modules/types/notification";
import { lastUpdateDate } from "modules/utils/date";
import { NotificationStatus } from "../../common/components/notification-status/notification-status.component";
import { NotificationProps } from "../../notification.component";

const { Text } = Typography;

export const DictionaryScript = (props: NotificationProps) => {
	const getScriptTitle = (notif: Notification) => {
		let scriptNameUi = null;
		if (notif?.metadata?.scriptName)
			scriptNameUi = notif?.metadata?.scriptName
				.toLowerCase()
				.replace(/_/g, " ");
		let actionPrefix = "is running";
		if (notif.metadata.operationStatus === "SUCCESS")
			actionPrefix = "ran successfully";
		if (notif.metadata.operationStatus === "FAILED")
			actionPrefix = "failed to run";

		return (
			<Text>
				<NotificationStatus {...props} />
				Script "{scriptNameUi}" on dictionary{" "}
				<strong>{notif.metadata.ressourceName}</strong> {actionPrefix}
			</Text>
		);
	};

	return (
		<List.Item
			className={
				(props.unreadNotification ? "un" : "") + "read-notification"
			}
		>
			<List.Item.Meta
				title={getScriptTitle(props.notification)}
				description={lastUpdateDate(props.notification.createdAt!)}
			/>
		</List.Item>
	);
};

import { List, Typography } from "antd";
import { lastUpdateDate } from "modules/utils/date";
import { NotificationStatus } from "../../common/components/notification-status/notification-status.component";
import { NotificationProps } from "../../notification.component";

const notificationsTitleStyle = {
	fontWeight: 300,
	fontSize: "13px",
};

export const GroupJoined = (props: NotificationProps) => {
	return (
		<List.Item
			className={
				(props.unreadNotification ? "un" : "") + "read-notification"
			}
			onClick={() => {
				if (props.redirectToGroup)
					props.redirectToGroup(props.notification.metadata);
			}}
			style={{ cursor: "pointer" }}
		>
			<List.Item.Meta
				title={
					<Typography.Text style={notificationsTitleStyle}>
						<NotificationStatus {...props} />
						You joined{" "}
						<strong>{props.notification.metadata.groupName}</strong>
					</Typography.Text>
				}
				description={lastUpdateDate(props.notification.createdAt!)}
			/>
		</List.Item>
	);
};

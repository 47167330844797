import { RightOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { EditableRessourceType } from "modules/types/global";
import { useState } from "react";
import "../../styles/main-menu.style.less";

type MenuTabLinkProps = {
	name: string;
	value: EditableRessourceType;
	setEditableRessourceType: React.Dispatch<
		React.SetStateAction<EditableRessourceType>
	>;
	ressourceType: EditableRessourceType;
};

export const MenuTabLink = (props: MenuTabLinkProps) => {
	const [hover, setHover] = useState(false);

	const MenuLinkClass = classNames({
		"tab-link": true,
		"tab-link-selected": props.ressourceType === props.value,
		"tab-link-hover": hover,
	});

	return (
		<div
			className={MenuLinkClass}
			onClick={(e) => props.setEditableRessourceType(props.value)}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}>
			{props.name}
			<RightOutlined style={{ float: "right", lineHeight: "24px" }} />
		</div>
	);
};

import {
	BodyElement,
	PageParams,
	EditableRessourceType,
} from "modules/types/global";
import { SearchListStore } from "./store/search-list.store.service";

export class SearchListService extends SearchListStore {
	/**
	 * Retrieve ressource from any collection
	 * @param ressourceType Type of the ressource
	 * @param urlParams.skip Number of document to skip
	 * @param urlParams.limit Number of document to retrieve
	 * @param urlParams.query Query to search in name
	 * @param body.filter Filters to apply
	 * @param body.sort Sorters to apply
	 * @param body.$in Ressource list to retrieve
	 * @param body.$nin Ressource to exclude from the liste
	 * @returns Ressource list
	 */
	public retrieveRessourcesPaginated = async (
		ressourceType: EditableRessourceType,
		urlParams: PageParams,
		body: BodyElement
	) => {
		if (Array.isArray(body.$in) && !body.$in.length) {
			return { count: 0, documents: [] };
		}
		return await this.retrieveRessourcesPaginatedStore(
			ressourceType,
			urlParams,
			body
		);
	};
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Button } from "antd";
import { Spinner } from "common/components/spinner/spinner.component";
import {
	BodyElement,
	FilterRessource,
	PageParams,
	EditableRessourceRealType,
	EditableRessourceType,
	SortRessource,
} from "modules/types/global";
import { getPathFromRessource } from "modules/utils/ressource";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/main-menu.style.less";
import { SearchListService } from "./services/search-list.service";

type SearchListProps = {
	ressourceType: EditableRessourceType;
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
	isActive: boolean;
};

export const SearchList = (props: SearchListProps) => {
	const [init, setInit] = useState(false);
	const [status, setStatus] = useState<"fetching" | "fetched">("fetched");
	const [timer, setTimer] = useState<any>(null);
	const [ressourceList, setRessourceList] = useState<
		EditableRessourceRealType[]
	>([]);
	const [count, setCount] = useState(0);

	const [pageParams, setPageParams] = useState<PageParams>({
		query: "",
		skip: 0,
		limit: 10,
		currentPage: 1,
	});

	const [lastPageParams, setLastPageParams] = useState<PageParams>({
		query: "",
		skip: 0,
		limit: 10,
		currentPage: 1,
	});

	const [filter, setFilter] = useState<FilterRessource>({});
	const [lastFilter, setLastFilter] = useState<FilterRessource>({});

	const [sort, setSort] = useState<SortRessource>({
		updatedAt: -1,
	});
	const [lastSort, setLastSort] = useState<SortRessource>({
		updatedAt: -1,
	});

	const [lastEditableRessourceType, setLastEditableRessourceType] =
		useState<EditableRessourceType>();
	const [lastIsActive, setLastIsActive] = useState(false);

	const service = new SearchListService();

	useEffect(() => {
		(async () => {
			try {
				if (
					!init ||
					JSON.stringify(props.ressourceType) !==
						JSON.stringify(lastEditableRessourceType)
				) {
					await searchQuery();
					setInit(true);
				} else if (props.isActive !== lastIsActive) {
					setLastIsActive(props.isActive);
					if (props.isActive) await searchQuery();
				} else {
					if (
						JSON.stringify(pageParams) !==
							JSON.stringify(lastPageParams) ||
						JSON.stringify(filter) !== JSON.stringify(lastFilter) ||
						JSON.stringify(sort) !== JSON.stringify(lastSort)
					) {
						updating();
					}
				}
			} catch (error) {
				console.error(error);
			}
		})();
	}, [props.ressourceType, props.isActive, pageParams, filter, sort]);

	const updating = () => {
		clearTimeout(timer);
		let time = setTimeout(() => {
			searchQuery();
		}, 1050);
		setTimer(time);
	};

	const searchQuery = async () => {
		try {
			setStatus("fetching");
			let $in: string[] | "none" = "none",
				$nin: string[] | "none" = "none",
				body: BodyElement = { filter, sort };

			switch (props.ressourceType) {
				case "project":
					body = {
						...body,
						filter: {
							...body.filter,
							env: "dev",
						},
					};
					break;
				case "annotation-campaign":
					break;
				case "ontology":
					body = {
						...body,
						filter: {
							...body.filter,
							type: "classification",
						},
					};
					break;
			}

			if ($in !== "none") body = { ...body, $in };
			if ($nin !== "none") body = { ...body, $nin };

			const { count, documents } =
				await service.retrieveRessourcesPaginated(
					props.ressourceType,
					pageParams,
					{ ...body, select: "name" }
				);

			if (count) setCount(count);
			else setCount(documents.length);
			setRessourceList(documents);

			setLastPageParams({ ...pageParams });
			setLastFilter({ ...filter });
			setLastSort({ ...sort });
			setLastEditableRessourceType(props.ressourceType);

			setStatus("fetched");
		} catch (error) {
			console.error(error);
			setRessourceList([]);
			setCount(0);
			setStatus("fetched");
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setPageParams({
			...pageParams,
			query: event.target.value.toLowerCase(),
		});

	// let itemsMap: any = {};
	// if (props.ressourceType === "ontology") {
	// 	itemsMap = props[props.ressourceType]?.ontologyMap;
	// } else if (props.ressourceType === "dataset") {
	// 	itemsMap = props[props.ressourceType]?.datasetMap;
	// } else {
	// 	itemsMap = props[props.ressourceType]?.map;
	// }
	// let items = typeof itemsMap == "object" ? Object.values(itemsMap) : [];

	// /**
	//  * @temporary
	//  * Hide classification ontology until all features are deployed
	//  */
	// if (props.ressourceType === "ontology")
	// 	items = items.filter((i: any) => i.type !== "classification");

	// if (!Array.isArray(items)) items = [];
	// items = items
	// 	.filter((_elem) => {
	// 		let index = _elem?.name
	// 			?.toLowerCase()
	// 			?.indexOf(query?.toLowerCase());

	// 		if (
	// 			props.ressourceType === "project" &&
	// 			_elem.env &&
	// 			_elem?.env !== "dev"
	// 		)
	// 			return false;

	// 		return typeof index === "number" && index !== -1;
	// 	})
	// 	.sort(
	// 		(a, b) =>
	// 			new Date(b.createdAt).getTime() -
	// 			new Date(a.createdAt).getTime()
	// 	);

	return (
		<div className="main-menu-search-list">
			<Input.Search
				className="search-input"
				onChange={handleChange}
				placeholder="Search label keywords.."
			/>
			<div className="list-items">
				{status === "fetched" ? (
					<>
						{ressourceList
							.slice(0, pageParams.limit)
							.map((_elem) => {
								return (
									<div className="link-item" key={_elem._id}>
										<Link
											onClick={() =>
												props.setIsActive(false)
											}
											to={`${getPathFromRessource(
												props.ressourceType
											)}view/${_elem._id}`}
										>
											{_elem.name}
										</Link>
									</div>
								);
							})}
						{count > pageParams.limit ? (
							<Button
								type="text"
								style={{ marginLeft: "12px" }}
								onClick={() =>
									setPageParams({
										...pageParams,
										limit: pageParams.limit + 10,
									})
								}
							>
								Show more
							</Button>
						) : null}
					</>
				) : (
					<Spinner noMarginTop title=" " />
				)}
			</div>
		</div>
	);
};

import { List, Button, Typography } from "antd";
import { lastUpdateDate } from "modules/utils/date";
import { useNavigate } from "react-router";
import { NotificationStatus } from "../../common/components/notification-status/notification-status.component";
import { NotificationProps } from "../../notification.component";
import { NotificationGroupService } from "./services/notification-group.service";

export const GroupInvitation = (props: NotificationProps) => {
	const navigate = useNavigate();

	const notificationGroupService: NotificationGroupService =
		new NotificationGroupService();

	const handleAccept = async () => {
		const result = await notificationGroupService.acceptInvitation(
			props.notification._id,
			props.notification.metadata.invitationId!
		);
		if (result.data._id) navigate(`nlp/group/view/${result.data._id}`);
	};

	if (props.notification.status === "COMPLETED") {
		return (
			<List.Item
				className={
					(props.unreadNotification ? "un" : "") + "read-notification"
				}
				actions={[]}
			>
				<List.Item.Meta
					title={
						<Typography.Text>
							<NotificationStatus {...props} />
							You joined{" "}
							<strong>
								{props.notification.metadata.groupName}
							</strong>
						</Typography.Text>
					}
					description={lastUpdateDate(props.notification.createdAt!)}
				/>
			</List.Item>
		);
	}

	return (
		<List.Item actions={[<Button onClick={handleAccept}>Accept</Button>]}>
			<List.Item.Meta
				title={
					<Typography.Text>
						<strong>
							{props.notification?.metadata?.senderName}
						</strong>
						{" invited you to join "}
						<strong>
							{props.notification?.metadata?.groupName}
						</strong>
					</Typography.Text>
				}
				description={lastUpdateDate(props.notification.createdAt!)}
			/>
		</List.Item>
	);
};

/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "store/store";
import { Pattern } from "modules/types/pattern";
import { OwnerType } from "modules/types/user";

export const resetPatterns = (patterns: Pattern[]): AnyAction => {
	return store.dispatch({
		type: "RESET_PATTERNS",
		payload: patterns,
	});
};

export const setPatterns = (patterns: Pattern[]): AnyAction => {
	return store.dispatch({
		type: "SET_PATTERNS",
		payload: patterns,
	});
};

export const addPattern = (pattern: Pattern): AnyAction => {
	return store.dispatch({
		type: "ADD_PATTERN",
		payload: pattern,
	});
};

export const updatePattern = (pattern: Pattern): AnyAction => {
	return store.dispatch({
		type: "UPDATE_PATTERN",
		payload: pattern,
	});
};

export const updatePatternOwnerType = (
	ressourceId: string,
	ownerType: OwnerType,
	groupId: string
) => {
	return store.dispatch({
		type: "UPDATE_PATTERN_OWNER",
		payload: { ressourceId, ownerType, groupId },
	});
};

export const updatePatterns = (patterns: Pattern[]): AnyAction => {
	return store.dispatch({
		type: "UPDATE_PATTERNS",
		payload: patterns,
	});
};

export const deletePattern = (pattern: Pattern): AnyAction => {
	return store.dispatch({
		type: "DELETE_PATTERN",
		payload: pattern,
	});
};

import { setNotifications } from "store/notification/notification-actions";
import { RetrieveNotificationApi } from "../api/retrieve-notification.api.service";

export class RetrieveNotificationsStore extends RetrieveNotificationApi {
	/**
	 * Retrieve all notification owned by user
	 * @returns null
	 */
	public retrieveNotifications = async () => {
		const result = await this.retrieveNotificationsApi();
		setNotifications(result.notifications);
	};
}

import {
	Form,
	Input,
	Checkbox,
	Button,
	Switch,
	Row,
	Col,
	Alert,
	Divider,
	Space,
	Typography,
	Tooltip,
} from "antd";
import { AuthService } from "../auth/services/auth.service";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import "./styles/signup.style.less";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { SignupForm } from "./components/signup-form.component";

export interface Credentials {
	firstName: string | null;
	lastName: string | null;
	email: string | null;
	username: string | null;
	password: string | null;
	confirmPassword: string | null;
}

export const Signup = () => {
	return (
		<Row justify="center" className="signup-container">
			<Col span={12} className="left-column" style={{ width: "58%" }}>
				<img
					className="lettria-logo"
					alt=""
					src="/images/lettria.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/unlock-text.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/purposes.svg"
					style={{ marginLeft: "50%" }}
				/>
				<img
					alt=""
					src="/images/icons/clients.svg"
					style={{ bottom: "48px", position: "absolute" }}
				/>
			</Col>
			<Col span={12} className="right-column">
				<Typography.Title
					level={1}
					style={{
						margin: 0,
						wordBreak: "break-word",
					}}>
					Create an account
				</Typography.Title>
				<p className="no-card">No credit card required</p>
				{/* <Button className="signup-google">
					<img
						className="google-logo"
						alt=""
						src="/images/logo-google.svg"
					/>
					Sign up with Google
				</Button> */}
				{/* <Divider style={{ marginBottom: "34px" }}>
					or with your email
				</Divider> */}
				<SignupForm />
			</Col>
		</Row>
	);
};

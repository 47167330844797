import { PayloadAction } from "@reduxjs/toolkit";
import { AnnotationData } from "modules/types/annotation-data";
import {
	AnnotationItem,
	AnnotationItemMap,
	AnnotationItemReducer,
	ByCampaignConfigItem,
	Links,
	Relationship,
	TokenMerged,
} from "modules/types/annotation-item";

export const resetAnnotationItems = (
	state: AnnotationItemReducer,
	action: PayloadAction<AnnotationItem[]>
) => {
	let map: AnnotationItemMap = {};

	if (action.payload?.length > 0)
		action.payload.forEach((item: AnnotationItem) => {
			map[item._id] = item;
		});

	return {
		...state,
		map,
		init: true,
	};
};

export const setAnnotationItems = (
	state: AnnotationItemReducer,
	action: PayloadAction<AnnotationItem[]>
) => {
	let map = { ...state.map };

	if (action.payload?.length > 0)
		action.payload.forEach((item: AnnotationItem) => {
			map[item._id] = { ...map[item._id], ...item };
		});

	return {
		...state,
		map,
		init: true,
	};
};

export const updateAnnotationItem = (
	state: AnnotationItemReducer,
	action: PayloadAction<AnnotationItem>
) => {
	let map = { ...state.map };
	map[action.payload?._id] = action.payload;

	return {
		...state,
		map,
	};
};

export const deleteAnnotationItem = (
	state: AnnotationItemReducer,
	action: PayloadAction<string>
) => {
	let map = { ...state.map };
	delete map[action.payload];

	return {
		...state,
		map,
	};
};

export const setAnnotationItemByCampaign = (
	state: AnnotationItemReducer,
	action: PayloadAction<{
		list: AnnotationItem[];
		count: number;
		config: ByCampaignConfigItem;
	}>
) => {
	const { list, config, count } = action.payload;
	let byCampaign = { ...state.byCampaign };
	let map = { ...state.map };

	let annotationItemIds = list.map((item: AnnotationItem) => {
		map[item._id] = item;
		return item._id;
	});

	byCampaign[config.annotationCampaignId] = {
		config,
		list: annotationItemIds,
		count,
	};

	return {
		...state,
		init: true,
		byCampaign,
		map,
	};
};

export const setAnnotationItemModifying = (
	state: AnnotationItemReducer,
	action: PayloadAction<AnnotationItem>
) => {
	return {
		...state,
		modifying: action.payload,
		init: true,
	};
};

export const setLinks = (
	state: AnnotationItemReducer,
	action: PayloadAction<Links[]>
) => {
	return {
		...state,
		modifying: {
			...state.modifying,
			links: action.payload,
		},
	};
};

export const setRelationships = (
	state: AnnotationItemReducer,
	action: PayloadAction<Relationship[]>
) => {
	return {
		...state,
		modifying: {
			...state.modifying,
			relationships: action.payload,
		},
	};
};

export const deleteRelationship = (
	state: AnnotationItemReducer,
	action: PayloadAction<number>
) => {
	const modifying = { ...state.modifying };
	let _relationships = [...modifying.relationships];
	_relationships.splice(action.payload, 1);

	return {
		...state,
		modifying: {
			...state.modifying,
			relationships: _relationships,
		},
	};
};

export const setRelationshipProperty = (
	state: AnnotationItemReducer,
	action: PayloadAction<{ relationshipKey: number; propertyId: string }>
) => {
	const modifying = { ...state.modifying };
	const { relationshipKey, propertyId } = action.payload;

	let _relationships = [...modifying.relationships];
	if (_relationships[relationshipKey].properties[0])
		_relationships[relationshipKey].properties[0].propertyId = propertyId;
	else
		_relationships[relationshipKey].properties.push({
			propertyId: propertyId,
		});

	return {
		...state,
		modifying: {
			...state.modifying,
			relationships: _relationships,
		},
	};
};

export const setRelationshipClass = (
	state: AnnotationItemReducer,
	action: PayloadAction<{
		index: number;
		oldClassId: string;
		newClassId: string;
	}>
) => {
	let modifying = { ...state.modifying };

	modifying.relationships.forEach((relationship) => {
		if (relationship.tags.owner[0] === action.payload.index) {
			if (relationship.properties[0]) {
				if (
					relationship.properties[0].classId ===
					action.payload.oldClassId
				) {
					relationship.properties[0] = {
						classId: action.payload.newClassId,
						propertyId: "",
					};
				}
			} else {
				relationship.properties.push({
					classId: action.payload.newClassId,
					propertyId: "",
				});
			}
		}
	});

	return {
		...state,
		modifying,
	};
};

export const setTokenMerged = (
	state: AnnotationItemReducer,
	action: PayloadAction<TokenMerged>
) => {
	return {
		...state,
		modifying: {
			...state.modifying,
			tokenMerged: action.payload,
		},
	};
};

/**
 * TODO: DELETE ToAnnotate in AnnotationDataReducer
 * @param state
 * @returns
 */
export const resetModifying = (
	state: AnnotationItemReducer,
	action: PayloadAction<{ toAnnotate?: AnnotationData }>
) => {
	const modifying: AnnotationItem = {
		_id: "",
		dataArray: [],
		links: [],
		relationships: [],
		status: "",
		timeElapsed: 0,
		annotationCampaignId: "",
		tokenMerged: {},
		// tokenMerged: action.payload.toAnnotate?.tokenMerged
		// 	? action.payload.toAnnotate.tokenMerged
		// 	: {},
		owner: "",
		auhtor: "",
		data: [],
		createdAt: "",
		updatedAt: "",
	};

	return {
		...state,
		modifying,
	};
};

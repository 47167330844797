/* eslint-disable react-hooks/exhaustive-deps */
import PrivateRoutes from "./routes/private.routes";
import { Layout } from "antd";
import HeaderComponent from "./components/header/header.component";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { useEffect, useState } from "react";
import { Spinner } from "common/components/spinner/spinner.component";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import { useLocation } from "react-router";

type PrivateLayoutOwnProps = {};

const PrivateLayout = (props: PrivateLayoutProps) => {
	const location = useLocation();
	const [isRetrieve, setIsRetrieve] = useState<boolean>(false);
	const service = new RetrieveDataService();

	const trackCustomerNavigation = () => {
		if (window?._cio) {
			let split = location.pathname.split("/")?.filter((e) => e !== "");
			window._cio.page(location.pathname, {
				path: location.pathname,
				zone: split?.[0],
				ressourceType: split?.[1],
				page: split?.[2],
				pageDetail1: split?.[3],
				pageDetail2: split?.[4],
				pageDetail3: split?.[5],
				pageDetail4: split?.[6],
				pageDetail5: split?.[7],
				pageDetail6: split?.[8],
			});
		}
	};

	useEffect(() => {
		trackCustomerNavigation();
	}, [location.pathname]);

	useEffect(() => {
		(async () => {
			try {
				await service.retrieveNotifications();
				await service.retrieveSubscriptions();
				await service.retrieveRessources([
					{
						type: "group",
						map: props.groupMap,
					},
				]);
				await service.retrieveRessources([
					{
						type: "project",
						map: props.projectMap,
					},
				]);
				setIsRetrieve(true);
			} catch (error) {
				console.error(error);
			}
		})();
	}, []);

	return isRetrieve ? (
		<Layout className="app-full-layout">
			<Layout className="app-layout">
				<HeaderComponent />
				<PrivateRoutes />
			</Layout>
		</Layout>
	) : (
		<Spinner header text="Retrieve all data..." />
	);
};

const mapState = (state: RootState) => ({
	groupMap: state.group.map,
	projectMap: state.project.map,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PrivateLayoutProps = PropsFromRedux & PrivateLayoutOwnProps;

export default connector(PrivateLayout);

import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import "./spinner.style.less";

type SpinnerProps = {
	text?: string;
	noMarginTop?: boolean;
	header?: boolean;
	fontSize?: number;
	title?: string;
};

export const Spinner = (props: SpinnerProps) => {
	let fontSize = props?.fontSize ? props.fontSize : 50;
	let title = props?.title ? props.title : "Loading";

	return (
		<>
			<div
				style={
					!props.noMarginTop
						? props.header
							? { marginTop: "calc(40ch + 97px)" }
							: { marginTop: "40ch" }
						: { padding: "24px" }
				}
			>
				<Typography style={{ textAlign: "center" }}>
					<Typography.Paragraph>
						<Spin
							indicator={
								<LoadingOutlined
									style={{ fontSize, color: "#6b4eb6" }}
									spin
								/>
							}
						/>
					</Typography.Paragraph>
					<Typography.Title level={5}>{title}</Typography.Title>
					{props.text ? (
						<Typography.Paragraph>
							{props.text}
						</Typography.Paragraph>
					) : null}
				</Typography>
			</div>
		</>
	);
};

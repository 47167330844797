import { useEffect } from "react";
import { useLocation } from "react-router";
import { AuthService } from "../auth/services/auth.service";

export const CognitoAuthRelay = () => {
	const authService: AuthService = new AuthService();

	const location = useLocation();

	useEffect(() => {
		(async () => {
			let code = new URLSearchParams(location.search).get("code");
			if (code) {
				authService.signInCognito(code);
			}
		})();
	}, [location.search]);

	return <></>;
};

export default CognitoAuthRelay;

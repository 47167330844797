/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import RoutesManager from "./views/routes/routes-manager.component";
import axios from "axios";
import { ConfigProvider } from "antd";
import { intializePushHandler } from "./services/app/service";
import "./index.less";

const prod = "https://api.app.lettria.com/app/";
const preprod = "https://preprod.api.app.lettria.com/app/";
const dev = "https://dev.api.app.lettria.com/app/";
const localhost = "http://localhost:4895/app/";

axios.defaults.baseURL = `${prod}`;

// axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}`;

// Hide all findDOMNode related errors caudes by ant-d with React StrictMode
const consoleError = console.error.bind(console);
console.error = (errObj, ...args) => {
	if (
		// process.env.NODE_ENV === "development" &&
		typeof errObj.message === "string" &&
		args.includes("findDOMNode")
	) {
		return;
	}
	consoleError(errObj, ...args);
};

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ConfigProvider
				getPopupContainer={(triggerNode: HTMLElement | undefined) =>
					(triggerNode?.parentNode as HTMLElement) || document.body
				}
			>
				<RoutesManager />
			</ConfigProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

intializePushHandler();

serviceWorker.register();

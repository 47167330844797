import {
	notificationsRead,
	setAllNotificationsRead,
} from "store/notification/notification-actions";
import { NotificationApi } from "../api/notification.service";

export class NotificationStore extends NotificationApi {
	public readNotificationsStore = async (userId: string) => {
		const result = await this.readNotificationsApi();
		setAllNotificationsRead(true);
		notificationsRead(userId);
		return result;
	};
}

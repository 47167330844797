import { List, Typography } from "antd";
import { lastUpdateDate } from "modules/utils/date";
import { NotificationStatus } from "../../common/components/notification-status/notification-status.component";
import { NotificationProps } from "../../notification.component";

const CustomMessage = (props: NotificationProps) => {
	if (props.notification.metadata.operationStatus == "PROCESSING")
		return (
			<>
				Synchronization of{" "}
				<strong>{props.notification.metadata?.ressourceName}</strong>{" "}
				project data{" "}
				{props.notification.metadata?.env ? (
					<>
						{" "}
						for environnement{" "}
						<strong>{props.notification.metadata?.env}</strong>
					</>
				) : null}
			</>
		);
	if (props.notification.metadata.operationStatus == "FAILED")
		return (
			<>
				Error while synchronizing{" "}
				<strong>{props.notification.metadata?.ressourceName}</strong>{" "}
				project data{" "}
				{props.notification.metadata?.env ? (
					<>
						{" "}
						for environnement{" "}
						<strong>{props.notification.metadata?.env}</strong>
					</>
				) : null}
			</>
		);
	if (props.notification.metadata.operationStatus == "SUCCESS")
		return (
			<>
				Synchronization of{" "}
				<strong>{props.notification.metadata?.ressourceName}</strong>{" "}
				project data is done
				{props.notification.metadata?.env ? (
					<>
						{" "}
						for environnement{" "}
						<strong>{props.notification.metadata?.env}</strong>
					</>
				) : null}
			</>
		);
	return <></>;
};

export const ProjectDictionaryCacheUpdate = (props: NotificationProps) => {
	return (
		<List.Item
			className={
				(props.unreadNotification ? "un" : "") + "read-notification"
			}
		>
			<List.Item.Meta
				title={
					<Typography.Text>
						<NotificationStatus {...props} />
						<CustomMessage {...props} />
					</Typography.Text>
				}
				description={lastUpdateDate(props.notification.createdAt!)}
			/>
		</List.Item>
	);
};

import { AnyAction } from "@reduxjs/toolkit";
import { AnnotationItemReducer } from "modules/types/annotation-item";
import {
	AnnotationItemActionType,
	AnnotationItemListActionType,
	ByCampaignActionType,
	LinksActionType,
	RelationshipsActionType,
	TokenMergedActionType,
} from "modules/types/annotation-item/store";
import { NumberActionType, StringActionType } from "modules/types/global/store";
import * as methods from "./annotation-item-methods";

const initialState: AnnotationItemReducer = {
	init: false,
	byCampaign: {},
	map: {},
	modifying: {
		_id: "",
		dataArray: [],
		links: [],
		relationships: [],
		status: "",
		timeElapsed: 0,
		annotationCampaignId: "",
		tokenMerged: {},
		owner: "",
		auhtor: "",
		data: [],
		createdAt: "",
		updatedAt: "",
	},
};

export const annotationItemReducer = (
	state = initialState,
	action: AnyAction
): AnnotationItemReducer => {
	switch (action.type) {
		case "RESET_ANNOTATION_ITEMS": {
			return methods.resetAnnotationItems(
				state,
				action as AnnotationItemListActionType
			);
		}
		case "SET_ANNOTATION_ITEMS": {
			return methods.setAnnotationItems(
				state,
				action as AnnotationItemListActionType
			);
		}
		case "SET_ANNOTATION_ITEM_BY_CAMPAIGN": {
			return methods.setAnnotationItemByCampaign(
				state,
				action as ByCampaignActionType
			);
		}
		case "SET_ANNOTATION_ITEM_MODIFYING": {
			return methods.setAnnotationItemModifying(
				state,
				action as AnnotationItemActionType
			);
		}
		case "SET_LINKS": {
			return methods.setLinks(state, action as LinksActionType);
		}
		case "SET_RELATIONSHIPS": {
			return methods.setRelationships(
				state,
				action as RelationshipsActionType
			);
		}

		case "SET_RELATIONSHIP_CLASS": {
			return methods.setRelationshipClass(state, action as any);
		}
		case "SET_RELATIONSHIP_PROPERTY": {
			return methods.setRelationshipProperty(state, action as any);
		}
		case "DELETE_RELATIONSHIP": {
			return methods.deleteRelationship(
				state,
				action as NumberActionType
			);
		}
		case "SET_TOKEN_MERGED": {
			return methods.setTokenMerged(
				state,
				action as TokenMergedActionType
			);
		}

		case "UPDATE_ANNOTATION_ITEM": {
			return methods.updateAnnotationItem(
				state,
				action as AnnotationItemActionType
			);
		}
		case "DELETE_ANNOTATION_ITEM": {
			return methods.deleteAnnotationItem(
				state,
				action as StringActionType
			);
		}
		case "RESET_MODIFYING": {
			return methods.resetModifying(state, action as any);
		}
		default: {
		}
	}
	return state;
};

export default annotationItemReducer;

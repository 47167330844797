import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Menu, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
	Notification,
	NotificationMetaData,
	NotificationMap,
} from "modules/types/notification";
import { RootState } from "store/store";
import { DictionaryScript } from "./components/notification-dictionary/dictionary-script.component";
import { ProjectDictionaryLinking } from "./components/notification-project/project-dictionary-linking.component";
import { GroupInvitation } from "./components/notification-group/group-invitation.component";
import { GroupJoined } from "./components/notification-group/group-joined.component";
import { NotificationService } from "./services/notification.service";
import { UserData } from "modules/types/user";
import "./notification.style.less";
import { ProjectDictionaryCacheUpdate } from "./components/notification-project/project-dictionary-cache-update.component";
import { OntologyGenerateSuggestions } from "./components/notification-ontology/ontology-generate-suggestions.component";
import { DatasetImport } from "./components/notification-dataset/dataset-import.component";

const styleButton = {
	borderColor: "transparent",
	color: "white",
};

const outButton = {
	background: "#0b2540",
};

const hoverButton = {
	background: "rgba(255, 255, 255, 0.15)",
};

type NotificationMenuProps = {
	notificationMap: NotificationMap;
	userData: UserData;
	notificationService: NotificationService;
};

export type NotificationProps = {
	notification: Notification;
	redirectToGroup?: Function;
	unreadNotification?: boolean;
	userData?: UserData;
};

const NotificationManager = (props: NotificationManagerProps) => {
	const [count, setCount] = useState<number>(0);
	const [open, setOpen] = useState(false);
	const [hover, setHover] = useState(false);

	const notificationService: NotificationService = new NotificationService();

	let location = useLocation();
	useEffect(() => {
		setOpen(false);
	}, [location]);

	useEffect(() => {
		if (props.init) {
			setCount(
				notificationService.setNotificationsPendingCount(
					Object.values(props.notificationMap),
					props.userData!
				)
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.init, props.notificationMap]);

	const handleClickNotifications = async () => {
		try {
			if (open) {
				await notificationService.readNotifications(
					props.userData?._id!
				);
			}
			setOpen(!open);
		} catch (error) {
			console.error(error);
		}
	};

	if (!props.init) return <></>;

	return (
		<Dropdown
			overlay={
				<NotificationMenu
					notificationMap={props.notificationMap}
					notificationService={notificationService}
					userData={props.userData!}
				/>
			}
			placement="bottomRight"
			trigger={["click"]}
			arrow
			onOpenChange={handleClickNotifications}>
			<Badge count={count} size="small" offset={[-28, 8]}>
				<Button
					onMouseEnter={() => {
						setHover(true);
					}}
					onMouseLeave={() => {
						setHover(false);
					}}
					style={{
						...styleButton,
						...(hover ? hoverButton : outButton),
					}}
					shape="circle"
					onClick={handleClickNotifications}>
					<BellOutlined style={{ fontSize: "20px" }} />
				</Button>
			</Badge>
		</Dropdown>
	);
};

const NotificationMenu = (props: NotificationMenuProps) => {
	const notifications: Notification[] = Object.values(props.notificationMap);
	useEffect(() => {
		return () => {
			props.notificationService.readNotifications(props.userData?._id!);
		};
	}, []);
	if (!notifications) return <Spin />;
	return (
		<Menu className="notification-menu">
			{notifications?.length ? (
				notifications
					.sort(
						(a, b) =>
							new Date(b?.createdAt).getTime() -
							new Date(a?.createdAt).getTime()
					)
					.map((notification: Notification, index: number) => (
						<NotificationMenuItem
							key={index}
							notification={notification}
							userData={props.userData}
						/>
					))
			) : (
				<div style={{ textAlign: "center", padding: "30px" }}>
					No activity in the past 6 months
				</div>
			)}
		</Menu>
	);
};

const NotificationMenuItem = (props: NotificationProps) => {
	const navigate = useNavigate();
	const redirectToGroup = (notification: NotificationMetaData) => {
		if (notification && notification.groupId) {
			navigate(`/nlp/group/view/${notification.groupId}/members`);
		}
	};
	let unreadNotification =
		props.userData &&
		props.notification.seenByUserIds?.indexOf(props.userData?._id) == -1;

	switch (props.notification.type) {
		case "GROUP_INVITATION":
			return props.notification.status !== "ACCEPTED" ? (
				<GroupInvitation
					notification={props.notification}
					unreadNotification={unreadNotification}
				/>
			) : (
				<GroupJoined
					notification={props.notification}
					redirectToGroup={redirectToGroup}
					unreadNotification={unreadNotification}
				/>
			);
		case "PROJECT_DICTIONARY_CACHE_UPDATE":
			return (
				<ProjectDictionaryCacheUpdate
					notification={props.notification}
					unreadNotification={unreadNotification}
				/>
			);
		case "PROJECT_DICTIONARY_LINKING":
			return <></>;
			return (
				<ProjectDictionaryLinking
					notification={props.notification}
					unreadNotification={unreadNotification}
				/>
			);
		case "DICTIONARY_SCRIPT":
			return (
				<DictionaryScript
					notification={props.notification}
					unreadNotification={unreadNotification}
				/>
			);
		case "DATASET_IMPORT":
			return (
				<DatasetImport
					notification={props.notification}
					unreadNotification={unreadNotification}
				/>
			);
		case "ONTOLOGY_GENERATE_SUGGESTIONS":
			return (
				<OntologyGenerateSuggestions
					notification={props.notification}
					unreadNotification={unreadNotification}
				/>
			);

		default:
			return <Menu.Item>{props.notification.type}</Menu.Item>;
	}
};

const mapState = (state: RootState) => ({
	userData: state.user?.data,
	init: state.notification.init,
	notificationMap: state.notification.map,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type NotificationManagerProps = PropsFromRedux;

export default connector(NotificationManager);

import { CampaignMap } from "modules/types/annotation-campaign";
import { DatasetMap } from "modules/types/dataset";
import { DictionaryMap } from "modules/types/dictionary";
import { FileStorageMap } from "modules/types/file-storage";
import { RessourceType } from "modules/types/global";
import { GroupMap } from "modules/types/group";
import {
	ClassPropertyMap,
	KnowledgeCardMap,
	OntologyMap,
} from "modules/types/ontology";
import { PatternMap } from "modules/types/pattern";
import { ProjectMap } from "modules/types/project";
import { WorkspaceType } from "modules/types/workspace";

/**
 * Retrurn the name of the key for the ressource ids array
 * @param ressourceType
 * @returns
 */
export const getRessourceIdsFromRessourceType = (
	ressourceType: RessourceType,
	inProject?: boolean
) => {
	switch (ressourceType) {
		case "annotation-campaign":
			return inProject && "annotationCampaignIds";
		// case "annotation-data":
		// 	return "annotationDataIds";
		// case "annotation-item":
		// 	return "annotationItemIds";
		// case "training":
		// 	return "annotationTrainingIds";
		case "class-property":
			return "classPropertyIds";
		case "dataset":
			return "dataSetIds";
		case "dictionary":
			return "dictionaryIds";
		case "file-storage":
			return "fileStorageIds";
		case "group":
			return inProject && "groupIds";
		case "knowledge-card":
			return "knowledgeCardIds";
		case "ontology":
			return "ontologyIds";
		case "pattern":
			return "patternIds";
		case "project":
			return inProject && "projectIds";
		default:
			return false;
	}
};

/**
 * Return the name of the workspace for each ressource
 * @param ressourceType
 * @returns
 */
export const getWorkspaceFromRessource = (
	ressourceType: RessourceType
): WorkspaceType => {
	switch (ressourceType) {
		// case "annotation-data":
		// case "annotation-item":
		case "annotation-campaign":
			return "annotation";
		// case "training":
		case "project":
		case "dictionary":
		case "file-storage":
		case "dataset":
		case "group":
			return "nlp";
		case "ontology":
		case "knowledge-card":
		case "class-property":
		case "pattern":
			return "structuration";
	}
};

export const getPathFromRessource = (ressourceType: RessourceType) =>
	`${getWorkspaceFromRessource(ressourceType)}/${ressourceType}/`;

export const getRessourceMapFromRessourceType = (
	ressourceType: RessourceType,
	projectMap: ProjectMap,
	campaignMap: CampaignMap,
	dictionaryMap: DictionaryMap,
	patternMap: PatternMap,
	datasetMap: DatasetMap,
	fileStorageMap: FileStorageMap,
	ontologyMap: OntologyMap,
	groupMap: GroupMap,
	knowledgeCardMap?: KnowledgeCardMap,
	classPropertyMap?: ClassPropertyMap
) => {
	switch (ressourceType) {
		case "annotation-campaign":
			return campaignMap;
		case "class-property":
			return classPropertyMap
				? classPropertyMap
				: ({} as ClassPropertyMap);

		case "dictionary":
			return dictionaryMap;
		case "dataset":
			return datasetMap;
		case "file-storage":
			return fileStorageMap;
		case "knowledge-card":
			return knowledgeCardMap
				? knowledgeCardMap
				: ({} as KnowledgeCardMap);
		case "ontology":
			return ontologyMap;
		case "pattern":
			return patternMap;
		case "project":
			return projectMap;
		case "group":
			return groupMap;
	}
};

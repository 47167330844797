import { Notification } from "modules/types/notification";
import { UserData } from "modules/types/user";
import { notificationsRead } from "store/notification/notification-actions";
import { NotificationStore } from "./store/notification.store.service";

export class NotificationService extends NotificationStore {
	/**
	 * Read all Notifications
	 * @returns result
	 */
	public readNotifications = async (userId: string) => {
		const result = await this.readNotificationsStore(userId);
		return result;
	};

	/**
	 * Set pending notifications count
	 * @param notifications
	 * @returns
	 */
	public setNotificationsPendingCount = (
		notifications: Notification[],
		userData: UserData
	) => {
		let count: number = 0;
		notifications.forEach((notification: Notification) => {
			if (
				!notification?.seenByUserIds?.find((id) => {
					return id == userData?._id;
				})
			)
				++count;
		});
		return count;
	};
}

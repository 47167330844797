import { RequestService } from "services/request.service";

const requestService: RequestService = new RequestService();

export class RetrieveNotificationApi extends RequestService {
	public retrieveNotificationsApi = async () => {
		const result = await requestService.request(
			"notification/retrieve",
			"POST",
			{}
		);
		return result;
	};
}

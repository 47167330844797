import { NotificationGroupStore } from "./store/notification-group.store";

export class NotificationGroupService extends NotificationGroupStore {
	public acceptInvitation = async (
		notificationId: string,
		invitationId: string
	) => await this.acceptInvitationStore(notificationId, invitationId);

	public refuseInvitation = async (
		notificationId: string,
		invitationId: string
	) => {
		await this.refuseInvitationStore(notificationId, invitationId);
	};
}

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {
	Form,
	Row,
	Col,
	Input,
	Tooltip,
	Space,
	Switch,
	Button,
	Typography,
	Divider,
} from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { AuthService } from "views/public/auth/services/auth.service";
import {
	useValidCode,
	useValidPassword,
	useValidUsername,
} from "views/public/auth/hooks/useAuthHooks";

export interface Credentials {
	name: string | null;
	lastName: string | null;
	email: string | null;
	username: string | null;
	password: string | null;
	confirmPassword: string | null;
}

export const SendCodeForm = (props: any) => {
	const authService: AuthService = new AuthService();

	const [cognitorErrorMessage, setCognitorErrorMessage] = useState<
		string | null
	>(null);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [email, setEmail] = useState<string>("");
	const [emailError, setEmailError] = useState<string | null>(null);

	const validateEmail = (e: any) => {
		setEmailError(null);
		setEmail(e.target.value);
		setIsSubmit(false);
	};
	const sendCode = async (credentials: any): Promise<void> => {
		const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
		if (!pattern.test(email)) {
			return setEmailError("Please enter a valid email");
		}
		try {
			await authService.resendPasswordCode(email);
			props.setUsername(email);
			props.setCodeIsSend(true);
			setIsSubmit(false);
		} catch (error: any) {
			console.error("error", error);
			setCognitorErrorMessage(error.message);
		}
	};

	const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
		const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
		if (isSubmit) {
			setEmailError(null);

			if (!email) {
				return setEmailError("Please enter your email");
			} else if (!pattern.test(email)) {
				return setEmailError("Please enter a valid email");
			}
		}
	};

	return (
		<>
			<Row justify="center">
				<Col span={24}>
					<Typography.Title level={2}>
						Reset Password
					</Typography.Title>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={24}>
					<Typography.Paragraph>
						Enter your email and we will send you a code to reset
						your password.
					</Typography.Paragraph>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={24}>
					<Form
						name="basic"
						initialValues={{ remember: true }}
						onFinish={sendCode}
						onFinishFailed={onFinishFailed}>
						<Form.Item name="email">
							<Input
								// type="email"
								onChange={(e) => validateEmail(e)}
								prefix={
									<UserOutlined className="site-form-item-icon" />
								}
								placeholder="Email"
								className="inputs"
							/>
							{cognitorErrorMessage ? (
								<p style={{ color: "#ff4d4f" }}>
									{cognitorErrorMessage}
								</p>
							) : null}
							{emailError ? (
								<p style={{ color: "#ff4d4f" }}>{emailError}</p>
							) : null}
						</Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								onClick={() => {
									setIsSubmit(true);
								}}
								className="forgot-form-button">
								Send Code
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>

			<Row justify="center">
				<Col span={24}>
					<Divider />
				</Col>
			</Row>
			<Row justify="center">
				<Col span={24}>
					<Space>
						<Typography.Text>
							Already have an account?
						</Typography.Text>
						<Link to="/login">
							<Typography.Link>Login</Typography.Link>
						</Link>
					</Space>
				</Col>
			</Row>
		</>
	);
};

import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { addGroup } from "store/group/group-actions";
import { updateNotificationStatus } from "store/notification/notification-actions";
import { NotificationGroupApi } from "../api/notification-group.api.service";

export class NotificationGroupStore extends NotificationGroupApi {
	public retrieveDataService: RetrieveDataService = new RetrieveDataService();
	public acceptInvitationStore = async (
		notificationId: string,
		invitationId: string
	) => {
		const result = await this.acceptInvitationApi(invitationId);
		if (result?.data) {
			addGroup(result.data);
			this.retrieveDataService.retrieveMembers(result.data._id);
		}
		// this.retrieveDataService.retrieveProjects();
		// this.retrieveDataService.retrieveDictionaries();

		updateNotificationStatus(notificationId, "ACCEPTED");
		return result;
	};

	public refuseInvitationStore = async (
		notificationId: string,
		invitationId: string
	) => {
		const result = await this.refuseInvitationApi(invitationId);
		updateNotificationStatus(notificationId, "REFUSED");
		return result;
	};
}

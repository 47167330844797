import { ProjectMap, Project, ProjectReducer } from "modules/types/project";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";
import { OwnerType } from "modules/types/user";

export const resetProjects = (
	state: ProjectReducer,
	action: PayloadAction<Project[]>
): ProjectReducer => {
	let projects = formatKnowledgeBeforeRedux(action.payload) as Project[];
	let map: ProjectMap = {};

	projects.forEach((project) => {
		map[project?._id] = project;
	});
	return {
		...state,
		map,
		init: true,
	};
};

export const setProjects = (
	state: ProjectReducer,
	action: PayloadAction<Project[]>
): ProjectReducer => {
	let projects = formatKnowledgeBeforeRedux(action.payload) as Project[];
	let map: ProjectMap = { ...state.map };

	projects.forEach((project) => {
		map[project?._id] = { ...map[project?._id], ...project };
	});
	return {
		...state,
		map,
		init: true,
	};
};

export const addProject = (
	state: ProjectReducer,
	action: PayloadAction<Project>
) => {
	let projectsMap: ProjectMap = { ...state.map };
	projectsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: projectsMap,
	};
};

export const updateProject = (
	state: ProjectReducer,
	action: PayloadAction<Project>
) => {
	let projects = formatKnowledgeBeforeRedux([action.payload]) as Project[];
	let map: ProjectMap = { ...state.map };
	map[projects[0]?._id] = projects[0];

	return {
		...state,
		map,
	};
};

export const updateProjectOwnerType = (
	state: ProjectReducer,
	action: PayloadAction<{
		projectId: string;
		ownerType: OwnerType;
		groupId: string;
	}>
) => {
	let map = { ...state.map };
	const { projectId, ownerType, groupId } = action.payload;
	map[projectId].ownerType = ownerType;
	switch (ownerType) {
		case "Group":
			map[projectId].owner = groupId;
			map[projectId].ownerType = "Group";
			break;
		case "User":
			map[projectId].owner = map[projectId].author;
			map[projectId].ownerType = "User";
			break;
	}

	return {
		...state,
		map,
	};
};

export const deleteProject = (
	state: ProjectReducer,
	action: PayloadAction<Project>
) => {
	let projectsMap: ProjectMap = { ...state.map };
	delete projectsMap[action.payload._id];

	return {
		...state,
		map: projectsMap,
	};
};

/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "store/store";
import { Project } from "modules/types/project";
import { OwnerType } from "modules/types/user";

export const resetProjects = (projects: Project[]): AnyAction => {
	return store.dispatch({
		type: "RESET_PROJECTS",
		payload: projects,
	});
};

export const setProjects = (projects: Project[]): AnyAction => {
	return store.dispatch({
		type: "SET_PROJECTS",
		payload: projects,
	});
};

export const addProject = (project: Project): AnyAction => {
	return store.dispatch({
		type: "ADD_PROJECT",
		payload: project,
	});
};

export const updateProject = (project: Project): AnyAction => {
	return store.dispatch({
		type: "UPDATE_PROJECT",
		payload: project,
	});
};

export const updateProjectOwnerType = (
	projectId: string,
	ownerType: OwnerType,
	groupId: string
) => {
	return store.dispatch({
		type: "UPDATE_PROJECT_OWNER",
		payload: { projectId, ownerType, groupId },
	});
};

export const deleteProject = (project: Project): AnyAction => {
	return store.dispatch({
		type: "DELETE_PROJECT",
		payload: project,
	});
};

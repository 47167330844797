import { Category, CategoryTree } from "modules/types/annotation-campaign";

export const formatCampaigns = (campaigns: any[]) => {
	return campaigns.map((campaign) => {
		let _categories = formatCategories(campaign?.categories);
		return {
			...campaign,
			categories: _categories,
			categoriesTree: sortCategoryTree(
				formatCategoryTree([..._categories]),
				"alpha"
			),
		};
	});
};

export const formatCategories = (categories: Category[]) => {
	let categoryMap: { [key: string]: boolean } = {};
	categories.forEach((_elem) => (categoryMap[_elem.label] = true));
	let toAdd = [];
	for (let _category of categories) {
		if (_category.parent && !(_category.parent in categoryMap)) {
			categoryMap[_category.parent] = true;
			toAdd.push({
				label: _category.parent,
				description: "",
				parent: "",
			});
		}
	}
	categories = categories.concat(toAdd);
	return categories;
};

export const sortCategoryTree = (
	categoriesTree: CategoryTree[],
	sort: string,
	order = true
) => {
	const sortAlpha = (categoriesTree: CategoryTree[], order: boolean) => {
		categoriesTree.sort((a, b) =>
			order
				? a.title?.localeCompare(b.title)
				: b.title?.localeCompare(a.title)
		);
		categoriesTree.forEach((categoryTree) => {
			if (categoryTree.children.length) {
				sortAlpha(categoryTree.children, order);
			}
		});
		return categoriesTree;
	};

	switch (sort) {
		case "alpha":
			return sortAlpha(categoriesTree, order);
		default:
			return sortAlpha;
	}
};

export const formatCategoryTree = (
	categories: Category[],
	categoryTree: CategoryTree[] = []
): CategoryTree[] => {
	const length = categories.length;

	if (!length) return categoryTree;

	categories.forEach((category, index) => {
		if (!category?.parent) {
			categoryTree.push({
				key: category.label,
				title: category.label,
				label: category.label,
				description: category.description,
				parent: "",
				children: [],
			});
			categories.splice(index, 1);
		} else {
			depth(categoryTree, categories, category, index);
		}
	});

	if (categories.length) {
		if (categories.length !== length) {
			return formatCategoryTree(categories, categoryTree);
		} else if (categories.length === length) {
			console.warn("infinite loop");
		}
	}
	return categoryTree;
};

export const depth = (
	newCategories: CategoryTree[],
	categories: Category[],
	category: Category,
	index: number
) => {
	newCategories.forEach((newCategory) => {
		if (newCategory.title === category.parent) {
			newCategory.children.push({
				key: category.label,
				title: category.label,
				label: category.label,
				description: category.description,
				parent: category.parent,
				children: [],
			});

			categories.splice(index, 1);
		} else {
			if (newCategory.children.length) {
				depth(newCategory.children, categories, category, index);
			}
		}
	});
};

import {
	Form,
	Input,
	Checkbox,
	Button,
	Layout,
	Card,
	Row,
	Col,
	Alert,
	Divider,
	Typography,
} from "antd";
import { AuthService } from "../auth/services/auth.service";
import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../auth/contexts/authContext";
import "./styles/login.style.less";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

export interface Credentials {
	email: string;
	password: string;
	rememberMe: boolean;
}

export const Login = () => {
	const authService: AuthService = new AuthService();
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [cognitorErrorMessage, setCognitorErrorMessage] = useState<
		string | null
	>(null);
	const [email, setEmail] = useState<string>("");
	const [emailError, setEmailError] = useState<string | null>(null);

	const navigate = useNavigate();
	const validateEmail = (e: any) => {
		setEmailError(null);
		setEmail(e.target.value);
		setIsSubmit(false);
	};

	const onFinish = async (values: any) => {
		if (!isSubmit) {
			setIsSubmit(true);
		}
		const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
		if (!pattern.test(email)) {
			return setEmailError("Please enter a valid email");
		}

		if (email && values.password) {
			try {
				await authService.signIn(email, values.password, true);
				navigate("/nlp/project/list");
			} catch (err: any) {
				setCognitorErrorMessage(err?.message);

				// if (err.code === "UserNotConfirmedException") {
				// 	navigate("/verify");
				// }

				if (err === "User is not confirmed.") {
					navigate("/verify?email=" + email);
				}
			}
		}
		// else {
		// 	console.log("onFinish", values);
		// }
	};

	const onFinishFailed = (values: any) => {
		const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
		if (isSubmit) {
			setEmailError(null);

			if (!email) {
				return setEmailError("Please enter your email");
			} else if (!pattern.test(email)) {
				return setEmailError("Please enter a valid email");
			}
			// else {
			// 	console.log("email valid", email, pattern.test(email));
			// }
		}
	};

	const handleFormChange = () => {
		setIsSubmit(false);
		setCognitorErrorMessage(null);
	};

	return (
		<Row justify="center" className="login-container">
			<Col span={12} className="left-column" style={{ width: "58%" }}>
				<img
					className="lettria-logo"
					alt=""
					src="/images/lettria.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/unlock-text.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/purposes.svg"
					style={{ marginLeft: "50%" }}
				/>
				<img
					alt=""
					src="/images/icons/clients.svg"
					style={{
						bottom: "48px",
						position: "absolute",
						// background:
						// 	"linear-gradient(0deg, #646E7F, #646E7F), url(1200px-Leroy_Merlin.png)",
						backgroundBlendMode: "overlay, normal",
						flex: "none",
						order: "1",
						flexGrow: "0",
					}}
				/>
			</Col>
			<Col span={12} className="right-column">
				<Typography.Title
					level={1}
					style={{
						margin: 0,
						wordBreak: "break-word",
					}}
				>
					Login
				</Typography.Title>
				<p className="no-card">No credit card required</p>
				{/* <Button className="login-google">
					<img
						className="google-logo"
						alt=""
						src="/images/logo-google.svg"
					/>
					Login with Google
				</Button> */}
				{/* <Divider style={{ marginBottom: "34px" }}>
					or with your email
				</Divider> */}
				<Form
					name="normal_login"
					className="login-form"
					initialValues={{ remember: true }}
					onChange={handleFormChange}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<span>Email</span>
					<Form.Item name="email">
						<Input
							// type="email"
							onChange={(e) => validateEmail(e)}
							prefix={
								<UserOutlined className="site-form-item-icon" />
							}
							placeholder="Email"
							className="inputs"
						/>
						{cognitorErrorMessage ? (
							<p style={{ color: "#ff4d4f" }}>
								{cognitorErrorMessage}
							</p>
						) : null}
						{emailError ? (
							<p style={{ color: "#ff4d4f" }}>{emailError}</p>
						) : null}
					</Form.Item>
					<span>Password</span>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: "Please input your Password!",
							},
						]}
					>
						<Input.Password className="inputs" type="password" />
					</Form.Item>
					{/* <>{error}</> */}
					{/* <Form.Item>
						<Form.Item
							name="remember"
							valuePropName="checked"
							noStyle
						>
							<Checkbox>Remember me</Checkbox>
						</Form.Item>

					</Form.Item> */}
					<Link className="login-form-forgot" to="/forgot">
						Forgot password
					</Link>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="login-form-button"
							onClick={() => {
								setIsSubmit(true);
							}}
						>
							Login
						</Button>
					</Form.Item>
					Don’t have an account yet?{" "}
					<Link to={"/signup"} className="link">
						Create an account
					</Link>
				</Form>
			</Col>
		</Row>
	);
};

import { PayloadAction } from "@reduxjs/toolkit";
import { ConfigurationReducer } from "modules/types/annotation-configuration";

export const setSelectedLinkIndexStore = (
	state: ConfigurationReducer,
	action: PayloadAction<number>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				item: {
					...state.annotateConfiguration?.item,
					selectedLinkIndex: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setSelectedRelationshipIndexStore = (
	state: ConfigurationReducer,
	action: PayloadAction<number>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				item: {
					...state.annotateConfiguration?.item,
					selectedRelationshipIndex: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setRelationshipMatchingClassStore = (
	state: ConfigurationReducer,
	action: PayloadAction<string>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				item: {
					...state.annotateConfiguration?.item,
					relationshipMatchingClass: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

import { PatternMap, Pattern, PatternReducer } from "modules/types/pattern";
import { PayloadAction } from "@reduxjs/toolkit";
import { OwnerType } from "modules/types/user";

export const resetPatterns = (
	state: PatternReducer,
	action: PayloadAction<Pattern[]>
): PatternReducer => {
	let map: PatternMap = {};
	for (let pattern of action.payload) map[pattern?._id] = pattern;
	return {
		...state,
		map,
		init: true,
	};
};

export const setPatterns = (
	state: PatternReducer,
	action: PayloadAction<Pattern[]>
): PatternReducer => {
	let map: PatternMap = { ...state.map };
	for (let pattern of action.payload)
		map[pattern?._id] = { ...map[pattern?._id], ...pattern };
	return {
		...state,
		map,
		init: true,
	};
};

export const addPattern = (
	state: PatternReducer,
	action: PayloadAction<Pattern>
) => {
	let patternsMap: PatternMap = { ...state.map };
	patternsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: patternsMap,
	};
};

export const updatePattern = (
	state: PatternReducer,
	action: PayloadAction<Pattern>
) => {
	let patternsMap: PatternMap = { ...state.map };
	patternsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: patternsMap,
	};
};

export const updatePatternOwnerType = (
	state: PatternReducer,
	action: PayloadAction<{
		ressourceId: string;
		ownerType: OwnerType;
		groupId: string;
	}>
) => {
	let map = { ...state.map };
	const { ressourceId, ownerType, groupId } = action.payload;
	map[ressourceId].ownerType = ownerType;
	switch (ownerType) {
		case "Group":
			map[ressourceId].owner = groupId;
			map[ressourceId].ownerType = "Group";
			break;
		case "User":
			map[ressourceId].owner = map[ressourceId].author;
			map[ressourceId].ownerType = "User";
			break;
	}

	return {
		...state,
		map,
	};
};

export const updatePatterns = (
	state: PatternReducer,
	action: PayloadAction<Pattern[]>
) => {
	let patternsMap: PatternMap = { ...state.map };

	action.payload.forEach((_pattern) => {
		patternsMap[_pattern?._id] = _pattern;
	});

	return {
		...state,
		map: patternsMap,
	};
};

export const deletePattern = (
	state: PatternReducer,
	action: PayloadAction<Pattern>
) => {
	let patternsMap: PatternMap = { ...state.map };
	delete patternsMap[action.payload._id];

	return {
		...state,
		map: patternsMap,
	};
};

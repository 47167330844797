import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { Routes, Route, Navigate } from "react-router";
import "./styles/routes.style.less";
import Layout from "antd/lib/layout/layout";
import React, { Suspense } from "react";
import { Spinner } from "common/components/spinner/spinner.component";

const AdminRoutesComponent = React.lazy(
	() => import("./../../../private/admin/admin.routes")
);
const NlpRoutesComponent = React.lazy(
	() => import("./../../../private/nlp/nlp.routes")
);
const AnnotationRoutesComponent = React.lazy(
	() => import("./../../../private/annotation/annotation.routes")
);
const StructurationRoutesComponent = React.lazy(
	() => import("./../../../private/structuration/structuration.routes")
);

const PrivateRoutes = (props: RoutesManagerProps) => {
	return (
		<Layout style={{ marginTop: "47px" }}>
			<Suspense fallback={<Spinner text="Loading" />}>
				<Routes>
					<Route>
						<Route
							path="admin/*"
							element={<AdminRoutesComponent />}
						/>
						<Route path="nlp/*" element={<NlpRoutesComponent />} />
						<Route
							path="annotation/*"
							element={<AnnotationRoutesComponent />}
						/>
						<Route
							path="structuration/*"
							element={<StructurationRoutesComponent />}
						/>
						<Route
							path="*"
							element={
								<>
									<Navigate replace to="nlp" />
								</>
							}
						/>
					</Route>
				</Routes>{" "}
			</Suspense>
		</Layout>
	);
};

const mapState = (state: RootState) => {
	let userRole: string = "";
	if (state.user && state.user.data)
		userRole = state.user.data?.role as string;

	return {
		role: userRole,
		// user,
		// init: project.init && dictionary.init,
	};
};
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RoutesManagerProps = PropsFromRedux;

export default connector(PrivateRoutes);

import {
	DictionaryMap,
	Dictionary,
	DictionaryReducer,
	QueryResponse,
} from "modules/types/dictionary";
import { PayloadAction } from "@reduxjs/toolkit";
import { OwnerType } from "modules/types/user";

/**
 * DICTIONARY STATE
 */

export const resetDictionaries = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary[]>
): DictionaryReducer => {
	let map: DictionaryMap = {};
	for (let dictionary of action.payload) map[dictionary?._id] = dictionary;
	return {
		...state,
		map,
		init: true,
	};
};

export const setDictionaries = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary[]>
): DictionaryReducer => {
	let map: DictionaryMap = { ...state.map };
	for (let dictionary of action.payload)
		map[dictionary?._id] = { ...map[dictionary?._id], ...dictionary };
	return {
		...state,
		map,
		init: true,
	};
};

export const addDictionary = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary>
) => {
	let dictionariesMap: DictionaryMap = { ...state.map };
	dictionariesMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: dictionariesMap,
	};
};

export const updateDictionary = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary>
) => {
	let dictionariesMap: DictionaryMap = { ...state.map };
	dictionariesMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: dictionariesMap,
	};
};

export const updateDictionaryOwnerType = (
	state: DictionaryReducer,
	action: PayloadAction<{
		ressourceId: string;
		ownerType: OwnerType;
		groupId: string;
	}>
) => {
	let map = { ...state.map };
	const { ressourceId, ownerType, groupId } = action.payload;
	map[ressourceId].ownerType = ownerType;
	switch (ownerType) {
		case "Group":
			map[ressourceId].owner = groupId;
			map[ressourceId].ownerType = "Group";
			break;
		case "User":
			map[ressourceId].owner = map[ressourceId].author;
			map[ressourceId].ownerType = "User";
			break;
	}

	return {
		...state,
		map,
	};
};

export const deleteDictionary = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary>
) => {
	let dictionariesMap: DictionaryMap = { ...state.map };
	delete dictionariesMap[action.payload._id];

	return {
		...state,
		map: dictionariesMap,
	};
};

/**
 * WORDS STATE
 */

export const addDictionaryWord = (
	state: DictionaryReducer,
	action: PayloadAction<string>
) => {
	let { dictionaryWords, words } = state;

	if (dictionaryWords.indexOf(action.payload) === -1)
		dictionaryWords.unshift(action.payload);

	let index = words.indexOf(action.payload);
	if (index !== -1) words.splice(index, 1);

	return {
		...state,
		dictionaryWords,
		words,
	};
};

export const setDictionaryWords = (
	state: DictionaryReducer,
	action: PayloadAction<string[]>
) => {
	let { dictionaryWords, words } = state;

	action.payload.forEach((word) => {
		if (dictionaryWords.indexOf(word) === -1) dictionaryWords.unshift(word);
		let index = words.indexOf(word);
		if (index !== -1) words.splice(index, 1);
	});

	return {
		...state,
		dictionaryWords,
		words,
	};
};

export const deleteDictionaryWord = (
	state: DictionaryReducer,
	action: PayloadAction<string>
) => {
	let { dictionaryWords } = state;
	let index = dictionaryWords.indexOf(action.payload);
	if (index !== -1) dictionaryWords.splice(index, 1);
	return {
		...state,
		dictionaryWords,
	};
};

export const setSynonyms = (
	state: DictionaryReducer,
	action: PayloadAction<string[]>
) => {
	let { synonyms, words } = state;

	action.payload.forEach((word) => {
		if (synonyms.indexOf(word) === -1) synonyms.unshift(word);
		let index = words.indexOf(word);
		if (index !== -1) words.splice(index, 1);
	});

	return {
		...state,
		synonyms,
		words,
	};
};

export const deleteSynonym = (
	state: DictionaryReducer,
	action: PayloadAction<string>
) => {
	let { synonyms } = state;
	let index = synonyms.indexOf(action.payload);
	if (index !== -1) synonyms.splice(index, 1);
	return {
		...state,
		synonyms,
	};
};

export const setWords = (
	state: DictionaryReducer,
	action: PayloadAction<string[]>
) => {
	let { words } = state;
	action.payload.forEach((word) => {
		if (words.indexOf(word) === -1) words.unshift(word);
	});

	return {
		...state,
		words,
	};
};

export const clearDictionaryWords = (state: DictionaryReducer) => {
	return {
		...state,
		dictionaryWords: [],
	};
};

export const setCount = (
	state: DictionaryReducer,
	action: PayloadAction<number>
) => {
	let { count } = state;
	count = action.payload;
	return {
		...state,
		count,
	};
};

/**
 * @param state
 * @param action
 * @returns
 */
export const setSearchQuery = (
	state: DictionaryReducer,
	action: PayloadAction<QueryResponse>
) => {
	if (!action.payload.words) action.payload.words = [];
	if (!action.payload.customWords) action.payload.customWords = [];
	if (!action.payload.synonyms) action.payload.synonyms = [];
	if (!action.payload.count) action.payload.count = 0;

	return {
		...state,
		count: action.payload.count,
		words: action.payload.words,
		dictionaryWords: action.payload.customWords,
		synonyms: action.payload.synonyms,
	};
};

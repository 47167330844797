import {
	EditableRessourceType,
	PageParams,
	BodyElement,
} from "modules/types/global";
import { SearchListApi } from "../api/search-list.api.service";

export class SearchListStore extends SearchListApi {
	/**
	 *
	 * @param ressourceType
	 * @param urlParams
	 * @param body
	 * @returns
	 */
	protected retrieveRessourcesPaginatedStore = async (
		ressourceType: EditableRessourceType,
		urlParams: PageParams,
		body: BodyElement
	) =>
		await this.retrieveRessourcesPaginatedApi(
			ressourceType,
			urlParams,
			body
		);
}

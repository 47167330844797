import { setLoggedIn, setUser } from "store/user/user-actions";
import { AuthApi } from "../api/auth.api.service";
import { RoutesService } from "views/routes/services/routes.service";
import { Credentials as SignupCredentials } from "../../../signup/signup.component";
import { CognitoTokenResponse, CognitoUserSession } from "modules/types/user";
import registerNotificationServiceWorker from "../register-notification-service-worker";

export class AuthStore extends AuthApi {
	/**
	 * Thunks that call a dispatch to make api call
	 * @param email
	 * @param password
	 * @returns response from sign in api request {token: string, user: UserData}
	 */

	public signInThunk = async (
		email: string,
		password: string,
		rememberMe: boolean
	) => {
		console.log("signInThunk");
		const tokenResponse = await this.signInApi(email, password);
		if (tokenResponse?.accessToken)
			localStorage.setItem(
				"LettriaAccessToken",
				tokenResponse.accessToken?.jwtToken
			);
		if (tokenResponse?.idToken)
			localStorage.setItem(
				"LettriaIdToken",
				tokenResponse.idToken.jwtToken
			);
		if (tokenResponse?.refreshToken)
			localStorage.setItem(
				"LettriaRefreshToken",
				tokenResponse.refreshToken.token
			);

		console.log("tokenResponse", tokenResponse);
		const getSelfResponse = await this.getSelfApi(
			tokenResponse?.idToken.jwtToken!
		);
		console.log("getSelfResponse", getSelfResponse);
		if (getSelfResponse?._id) {
			setUser(getSelfResponse);
			setLoggedIn(true);
		}
	};

	public signUpThunk = async (credentials: SignupCredentials) => {
		console.log("auth.store.service credentials", credentials);
		const tokenResponse = await this.signUpApi(
			credentials.firstName!,
			credentials.lastName!,
			credentials.email!,
			credentials.password!
		);
		// console.log("auth.store.service tokenResponse", tokenResponse);
	};

	/**
	 * Sign out function that remove token from localStorage
	 */
	public signOutThunk = async () => {
		const routesService: RoutesService = new RoutesService();
		localStorage.removeItem("LettriaRefreshToken");
		localStorage.removeItem("LettriaIdToken");
		localStorage.removeItem("LettriaAccessToken");
		// setLoggedIn(false);
		window.location.reload();

		// await routesService.restoreSession();
		return true;
	};

	public signInCognitoThunk = async (code: string) => {
		try {
			const tokenResponse = await this.signInCognitoApi(code);
			if (tokenResponse?.access_token)
				localStorage.setItem(
					"LettriaAccessToken",
					tokenResponse.access_token
				);
			if (tokenResponse?.id_token)
				localStorage.setItem("LettriaIdToken", tokenResponse.id_token);
			if (tokenResponse?.refresh_token)
				localStorage.setItem(
					"LettriaRefreshToken",
					tokenResponse.refresh_token
				);

			const getSelfResponse = await this.getSelfApi(
				tokenResponse?.id_token!
			);
			if (getSelfResponse?._id) {
				setUser(getSelfResponse);
				setLoggedIn(true);
			}
		} catch (e) {
			console.error(e);
		}

		return true;
	};

	public restoreSessionThunk = async () => {
		try {
			let currentIdToken = localStorage.getItem("LettriaIdToken");
			if (!currentIdToken) {
				setLoggedIn(false);
				return true;
			}
			let tokenResponse: CognitoUserSession =
				await this.restoreSessionApi();
			// console.log("tokenResponse", tokenResponse);
			if (tokenResponse?.accessToken)
				localStorage.setItem(
					"LettriaAccessToken",
					tokenResponse.accessToken.jwtToken
				);
			if (tokenResponse.idToken)
				localStorage.setItem(
					"LettriaIdToken",
					tokenResponse.idToken.jwtToken
				);
			if (tokenResponse?.refreshToken)
				localStorage.setItem(
					"LettriaRefreshToken",
					tokenResponse.refreshToken.token
				);

			registerNotificationServiceWorker();
			const getSelfResponse = await this.getSelfApi(
				tokenResponse.idToken.jwtToken!
			);
			if (getSelfResponse?._id) {
				setUser(getSelfResponse);
				setLoggedIn(true);
			}
		} catch (error: unknown) {
			// alert(error);
			setLoggedIn(false);
		}
	};
}

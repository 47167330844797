/* eslint-disable react-hooks/exhaustive-deps */
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
} from "react-router-dom";
import { Login } from "../public/login/login.component";
import PrivateLayout from "./private-layout/private-layout.component";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { useEffect } from "react";
import CognitoAuthRelay from "views/public/cognito-auth-relay/cognito-auth-relay.component";
import { AuthService } from "views/public/auth/services/auth.service";
import { Signup } from "views/public/signup/signup.component";
import { VerifyCode } from "views/public/verify/verify.component";
import ForgotPassword from "views/public/forgotPassword/forgotPassword";
import GeneralConditionsUse from "views/public/general-conditions-use";

const RoutesManager = (props: RoutesManagerProps) => {
	const authService: AuthService = new AuthService();

	useEffect(() => {
		authService.restoreSession();
	}, []);

	return (
		<Router>
			<Routes>
				{props.init ? (
					props.loggedIn ? (
						<>
							<Route path="/*" element={<PrivateLayout />} />
							<Route
								path="*"
								element={
									<>
										<Navigate replace to="/" />
									</>
								}
							/>
						</>
					) : (
						<>
							<Route
								path="/auth/cognito"
								element={<CognitoAuthRelay />}
							/>
							<Route path="/login" element={<Login />} />
							<Route path="/signup" element={<Signup />} />
							<Route path="/verify" element={<VerifyCode />} />
							<Route
								path="/gcu"
								element={<GeneralConditionsUse />}
							/>
							<Route
								path="/forgot"
								element={<ForgotPassword />}
							/>

							<Route
								path="*"
								element={
									<>
										<Navigate replace to="/login" />
									</>
								}
							/>
						</>
					)
				) : (
					<></>
				)}
			</Routes>
		</Router>
	);
};

const mapState = (state: RootState) => ({
	loggedIn: state.user.loggedIn as boolean,
	init: state.user.init as boolean,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RoutesManagerProps = PropsFromRedux;

export default connector(RoutesManager);

import {
	Ontology,
	ClassPropertyMap,
	ClassPropertyTree,
	KnowledgeCard,
	ClassPropertyList,
	ClassProperty,
	ClassPropertyTreeItem,
	ClassPropertyOption,
} from "modules/types/ontology";
import { getIdsListFromPath } from "./knowledge-card";

export const createClassPropertyTree = (classPropertyMap: ClassPropertyMap) => {
	let map: any = {};
	map = JSON.parse(JSON.stringify(classPropertyMap));
	let tree: ClassPropertyTreeItem[] = [];
	const classProperties = Object.values(
		JSON.parse(JSON.stringify(classPropertyMap)) as ClassPropertyMap
	).sort(
		(a: ClassProperty, b: ClassProperty) => b.path.length - a.path.length
	);

	classProperties.forEach((property) => {
		try {
			map[property._id].title = map[property._id].label;
			if (!map[property._id].children) map[property._id].children = [];

			const ids = property.path.split("/").filter((c) => c);

			if (ids.length) {
				map[property._id].key =
					map[property._id].path + "/" + map[property._id]._id;

				if (!map[ids[ids.length - 1]]?.children)
					map[ids[ids.length - 1]].children = [];

				if (
					map[ids[ids.length - 1]].children
						.map((c: any) => c._id)
						.indexOf(map[property._id]._id) === -1
				)
					map[ids[ids.length - 1]].children = [
						...map[ids[ids.length - 1]].children,
						map[property._id],
					];

				if (map[property._id].path !== "/") delete map[property._id];
			} else {
				map[property._id].key = "/" + map[property._id]._id;
			}
		} catch (error) {
			console.error({ error, property });
		}
	});
	tree = Object.values(map);

	return tree;
};

/**
 * Create class property list from class property tree
 * @param tree Class properties tree from label-hierarchy
 * @returns Class properties list generated
 */
export const formatClassPropertyTreeToList = (
	tree: ClassPropertyTreeItem[],
	arr: { _id: string; path: string }[] = []
) => {
	for (let item of tree) {
		let ids = item.key.split("/");
		let path = "";
		for (let i = 1; i < ids.length - 1; i++) path += "/" + ids[i];
		if (!path) path += "/";
		arr.push({
			_id: ids[ids.length - 1],
			path: path,
		});
		if (item.children) formatClassPropertyTreeToList(item.children, arr);
	}
	return arr;
};

/**
 * Update property path for dragObj and its children
 * @param path parent path
 * @param dragObj the object you're dragging
 * @returns dragObj with updated path
 */
export const updateClassPropertyTreePath = (
	path: string,
	dragObj: ClassPropertyTreeItem
) => {
	const ids = dragObj.key.split("/");
	const id = ids[ids.length - 1];
	dragObj.key = path + "/" + id;
	if (dragObj.children.length)
		for (let item of dragObj.children)
			updateClassPropertyTreePath(dragObj.key, item);
	return dragObj;
};

/**
 * Create double branch property tree
 * @param ontology
 * @param classPropertyMap
 * @returns
 */
export const formatPropertiesList = (
	ontology: Ontology,
	classPropertyMap: ClassPropertyMap
) => {
	let properties: ClassPropertyTree = {
		relationshipsTree: [],
		attributesTree: [],
	};
	if (classPropertyMap)
		Object.values(classPropertyMap).forEach((property) => {
			if (property.knowledgeId === ontology._id)
				if (property.propertyType === "relationship")
					properties.relationshipsTree.push({
						...property,
						title: property.label,
						key: property._id,
						children: [],
					});
				else if (property.propertyType === "attribute")
					properties.attributesTree.push({
						...property,
						title: property.label,
						key: property._id,
						children: [],
					});
		});
	return properties;
};

/**
 * Get properties list from attached properties on knowledge cards
 * @param knowledgeCard
 * @param ontology
 * @param classPropertyMap
 * @returns
 */
export const getPropertyList = (
	knowledgeCard: KnowledgeCard,
	ontology: Ontology,
	classPropertyMap: ClassPropertyMap
) => {
	const ids = [...getIdsListFromPath(knowledgeCard.path), knowledgeCard._id];
	let _classPropertyList: ClassPropertyList[] = [];
	if (ids.length)
		for (let i = 0; i < ids.length; i++) {
			if (ontology.knowledgeCardMap[ids[i]]) {
				_classPropertyList.unshift({
					classId: ontology.knowledgeCardMap[ids[i]]._id,
					className: ontology.knowledgeCardMap[ids[i]].name,
					properties: [],
				});
				if (ontology.knowledgeCardMap[ids[i]]?.propertyIds) {
					_classPropertyList[0].properties =
						ontology.knowledgeCardMap[ids[i]].propertyIds
							.map((id) => classPropertyMap[id])
							.filter((c) => c);
				}
			}
		}
	return _classPropertyList;
};

/**
 *
 * @param ontology
 * @param classPropertyList
 * @param classPropertyMap
 * @returns
 */
export const getAvailableProperties = (
	ontology: Ontology,
	classPropertyList: ClassPropertyList[],
	classPropertyMap: ClassPropertyMap
) => {
	return ontology.propertyIds
		.filter((propertyId) => {
			if (!classPropertyMap[propertyId]?._id) return null;
			return (
				classPropertyList
					.map((classproperty) => classproperty.properties)
					.flat()
					.map((property) => property._id)
					.indexOf(propertyId) === -1
			);
		})
		.map((id) => {
			return {
				_id: classPropertyMap[id]._id,
				label: classPropertyMap[id].label,
				type: classPropertyMap[id].propertyType,
				value: classPropertyMap[id].label,
			};
		})
		.filter((property) => property !== null);
};

export const sortPropertyAlphabetical = (
	order: boolean,
	properties?: ClassPropertyTree
) => {
	if (properties) {
		properties.relationshipsTree.sort((a, b) =>
			order
				? a.title.localeCompare(b.title)
				: b.title.localeCompare(a.title)
		);
		properties.attributesTree.sort((a, b) =>
			order
				? a.title.localeCompare(b.title)
				: b.title.localeCompare(a.title)
		);
	}
	return properties;
};

export const sortPropertyTreeAlphabetical = (
	propertiesTree: ClassPropertyTreeItem[],
	order = true
) => {
	propertiesTree.sort((a, b) =>
		order ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)
	);
	propertiesTree.forEach((propertyTree) => {
		if (propertyTree.children.length) {
			sortPropertyTreeAlphabetical(propertyTree.children, order);
		}
	});
	return propertiesTree;
};

export const sortPropertyCreatedAt = (
	order: boolean,
	properties?: ClassPropertyTree
) => {
	if (properties) {
		properties.relationshipsTree.sort((a, b) =>
			order
				? new Date(a.createdAt).getTime() -
				  new Date(b.createdAt).getTime()
				: new Date(a.createdAt).getTime() +
				  new Date(b.createdAt).getTime()
		);
		properties.attributesTree.sort((a, b) =>
			order
				? new Date(a.createdAt).getTime() -
				  new Date(b.createdAt).getTime()
				: new Date(a.createdAt).getTime() +
				  new Date(b.createdAt).getTime()
		);
	}
	return properties;
};

export const sortPropertyTreeCreatedAt = (
	propertiesTree: ClassPropertyTreeItem[],
	order = true
) => {
	propertiesTree.sort((a, b) =>
		order
			? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
			: new Date(a.createdAt).getTime() + new Date(b.createdAt).getTime()
	);
	propertiesTree.forEach((propertyTree) => {
		if (propertyTree.children.length) {
			sortPropertyTreeAlphabetical(propertyTree.children, order);
		}
	});
	return propertiesTree;
};

import axios from "axios";
import {
	CognitoTokenResponse,
	CognitoUserSession,
	UserData,
	UserResponse,
} from "modules/types/user";
import { RequestService } from "services/request.service";
import * as cognito from "../../libs/cognito";

export class AuthApi extends RequestService {
	public signInApi = async (
		email: string,
		password: string
	): Promise<CognitoUserSession> => {
		let userSession: CognitoUserSession = (await cognito.signInWithEmail(
			email,
			password
		)) as CognitoUserSession;
		return userSession;
	};

	public signUpApi = async (
		name: string,
		family_name: string,
		email: string,
		password: string
		// ): Promise<CognitoUserSession> => {
	): Promise<any> => {
		let userSession: CognitoUserSession =
			(await cognito.signUpUserWithEmail(
				name,
				family_name,
				email,
				email,
				password
				// )) as CognitoUserSession;
			)) as any;
		// console.log("signUp api", userSession);
		return userSession;
	};

	public signInCognitoApi = async (
		code: string
	): Promise<CognitoTokenResponse> => {
		let res: CognitoTokenResponse = await this.request(
			"aws/cognito-auth-token" + `?code=${code}`,
			"GET",
			null
		);
		return res;
	};

	private trackUserLogin = async (user: UserData) => {
		try {
			let createdAt = Math.ceil(
				new Date(user.createdAt)?.getTime() / 1000
			);

			console.log({
				id: user.email, // Use either id or email.

				created_at: createdAt, // Timestamp in your system that represents when

				first_name: user.profile.firstName, // Add any attributes you'd like to use in the email subject or body.
				last_name: user.profile.lastName,
			});
			window?._cio &&
				window._cio.identify({
					id: user.email, // Use either id or email.

					created_at: createdAt, // Timestamp in your system that represents when

					first_name: user.profile.firstName, // Add any attributes you'd like to use in the email subject or body.
					last_name: user.profile.lastName,
				});
		} catch (error) {
			console.error(error);
		}
	};

	public getSelfApi = async (idToken: string): Promise<UserData> => {
		// console.log("getSelfApi");

		let res: UserData = await this.request(
			"auth/get-self",
			"GET",
			null,
			idToken
		);
		this.trackUserLogin(res);

		// console.log("getSelfApi", res);

		return res;
	};

	public restoreSessionApi = async (): Promise<any> => {
		try {
			let res = await cognito.refreshUserToken(
				localStorage.getItem("LettriaRefreshToken")!
			);
			return res;

			// let res: any = await axios({
			// 	url: "aws/refresh-token",
			// 	method: "GET",
			// 	headers: {
			// 		Authorization: `LettriaRefreshToken ${localStorage.getItem(
			// 			"LettriaRefreshToken"
			// 		)}`,
			// 	},
			// });

			// return res?.data;
		} catch (error) {
			console.error(error);
		}
		return null!;
	};
}

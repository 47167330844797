/* eslint-disable no-unreachable */

import { AnyAction } from "@reduxjs/toolkit";
import { ConfigurationReducer } from "modules/types/annotation-configuration";
import {
	AnnotateDataActionType,
	ViewerModeActionType,
} from "modules/types/annotation-configuration/store";
import {
	BooleanActionType,
	DateActionType,
	NumberActionType,
	NumbersActionType,
	StringActionType,
	StringsActionType,
} from "modules/types/global/store";
import * as methods from "./methods";

const initialState: ConfigurationReducer = {
	init: false,
	annotateConfiguration: null,
};

export const annotationConfigurationReducer = (
	state = initialState,
	action: AnyAction
): ConfigurationReducer => {
	switch (action.type) {
		/**
		 * !! REDUCER TYPE !!
		 * --> GLOBAL ACTIONS
		 */
		case "SET_CONFIGURATION": {
			return methods.setConfiguration(state, action as any);
			break;
		}
		case "RESET_CONFIGURATION": {
			return methods.resetConfiguration(state);
			break;
		}

		/**
		 * !! REDUCER TYPE !!
		 * --> COMPONENT ACTIONS
		 */
		case "SET_CONFIGURATION_VIEWERMODE": {
			return methods.setViewerModeStore(
				state,
				action as ViewerModeActionType
			);
			break;
		}
		case "SET_CONFIGURATION_ANNOTATE_IS_EMPTY": {
			return methods.setAnnotateIsEmptyStore(
				state,
				action as BooleanActionType
			);
			break;
		}
		case "SET_CONFIGURATION_IS_FETCHING": {
			return methods.setIsFetchingStore(
				state,
				action as BooleanActionType
			);
			break;
		}
		case "SET_CONFIGURATION_SAVE_BUTTON_CLASS": {
			return methods.setSaveButtonClassStore(
				state,
				action as StringActionType
			);
			break;
		}
		case "SET_CONFIGURATION_START_ANNOTATING": {
			return methods.setStartAnnotatingStore(
				state,
				action as DateActionType
			);
			break;
		}
		case "SET_CONFIGURATION_UNCOMPLETE_RELATIONSHIPS": {
			return methods.setUncompleteRelationships(
				state,
				action as NumbersActionType
			);
			break;
		}

		/**
		 * !! REDUCER TYPE !!
		 * --> CAMPAIGN ACTIONS
		 */
		case "SET_CONFIGURATION_APPLYING_CATEGORY": {
			return methods.setApplyingCategoryStore(
				state,
				action as StringActionType
			);
			break;
		}
		case "SET_CONFIGURATION_AWAITING_CATEGORY": {
			return methods.setAwaitingApplyingCategoryStore(
				state,
				action as StringActionType
			);
			break;
		}
		case "SET_CONFIGURATION_VALUE": {
			return methods.setValueStore(state, action as StringsActionType);
			break;
		}

		/**
		 * !! REDUCER TYPE !!
		 * --> DATA ACTIONS
		 */
		case "SET_CONFIGURATION_SELECTED_INDEXES": {
			return methods.setSelectedIndexesStore(
				state,
				action as NumbersActionType
			);
			break;
		}
		case "SET_CONFIGURATION_MULTI_SELECTION": {
			return methods.setMultiSelectionStore(
				state,
				action as BooleanActionType
			);
			break;
		}
		case "SET_CONFIGURATION_ANNOTATION_DATA_ID": {
			return methods.setAnnotationDataIdStore(
				state,
				action as StringActionType
			);
			break;
		}
		case "SET_CONFIGURATION_ANNOTATE_DATA": {
			return methods.setAnnotateDataStore(
				state,
				action as AnnotateDataActionType
			);
			break;
		}

		case "SET_CONFIGURATION_TO_ANNOTATE":
			return methods.setToAnnotateStore(state, action as any);

		/**
		 * !! REDUCER TYPE !!
		 * --> ITEM ACTIONS
		 */

		case "SET_CONFIGURATION_LINK_INDEX": {
			return methods.setSelectedLinkIndexStore(
				state,
				action as NumberActionType
			);
			break;
		}
		case "SET_CONFIGURATION_RELATIONSHIP_INDEX": {
			return methods.setSelectedRelationshipIndexStore(
				state,
				action as NumberActionType
			);
			break;
		}
		case "SET_CONFIGURATION_RELATIONSHIP_CLASS": {
			return methods.setRelationshipMatchingClassStore(
				state,
				action as StringActionType
			);
			break;
		}

		default: {
			break;
		}
	}
	return state;
};

/*eslint no-unreachable: "off"*/
import { PatternReducer } from "modules/types/pattern";
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./pattern-methods";
import {
	PatternsActionType,
	PatternActionType,
} from "modules/types/pattern/store";

const initialState: PatternReducer = {
	init: false,
	map: {},
	patternCards: [],
};

export const patternReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case "RESET_PATTERNS": {
			return methods.resetPatterns(state, action as PatternsActionType);
			break;
		}
		case "SET_PATTERNS": {
			return methods.setPatterns(state, action as PatternsActionType);
			break;
		}
		case "ADD_PATTERN": {
			return methods.addPattern(state, action as PatternActionType);
			break;
		}
		case "UPDATE_PATTERN": {
			return methods.updatePattern(state, action as PatternActionType);
			break;
		}
		case "UPDATE_PATTERN_OWNER": {
			return methods.updatePatternOwnerType(state, action as any);
		}
		case "UPDATE_PATTERNS": {
			return methods.updatePatterns(state, action as PatternsActionType);
			break;
		}
		case "DELETE_PATTERN": {
			return methods.deletePattern(state, action as PatternActionType);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};

import {
	CheckCircleOutlined,
	InfoCircleFilled,
	InfoCircleOutlined,
	InfoCircleTwoTone,
	LoadingOutlined,
	WarningOutlined,
	WarningTwoTone,
} from "@ant-design/icons";
import { NotificationProps } from "../../../notification.component";

export const NotificationStatus = (props: NotificationProps) => {
	if (props.notification.metadata.operationStatus == "SUCCESS")
		return <CheckCircleOutlined style={{ color: "#14d735" }} />;
	if (props.notification.metadata.operationStatus == "PROCESSING")
		return <LoadingOutlined />;
	if (props.notification.metadata.operationStatus == "FAILED")
		return <WarningOutlined style={{ color: "#df3c3c" }} />;

	return (
		<>
			<InfoCircleOutlined style={{ color: "grey" }} />
			{/* {props.unreadNotification ? (
				<div className="unread-notification-indicator"></div>
			) : null} */}
		</>
	);
};

import { PayloadAction } from "@reduxjs/toolkit";
import {
	AnnotationData,
	AnnotationDataReducer,
	ByCampaignConfigData,
	PreAnnotation,
	ToAnnotateDataObject,
} from "modules/types/annotation-data";
import { TokenMerged } from "modules/types/annotation-item";

export const addAnnotationData = (
	state: AnnotationDataReducer,
	action: PayloadAction<AnnotationData>
) => {
	let map = { ...state.map };
	if (action.payload?._id) map[action.payload?._id] = action.payload;

	if (!map[action.payload?._id]?.dataObject?.annotate)
		map[action.payload?._id].dataObject.annotate = [];

	if (!map[action.payload?._id]?.dataObject?.pre)
		map[action.payload?._id].dataObject.pre = {};

	if (!map[action.payload?._id]?.dataObject?.tokenMerged)
		map[action.payload?._id].dataObject.tokenMerged = {};

	return {
		...state,
		map,
	};
};

export const deleteAnnotationDataByCampaign = (
	state: AnnotationDataReducer,
	action: PayloadAction<string>
) => {
	let byCampaign = { ...state.byCampaign };
	delete byCampaign[action.payload];

	return {
		...state,
		byCampaign,
	};
};

/**
 * TODO: Check return value in list
 * @param state
 * @param action
 * @returns
 */
export const setAnnotationDataByCampaign = (
	state: AnnotationDataReducer,
	action: PayloadAction<{
		list: AnnotationData[];
		count: number;
		config: ByCampaignConfigData;
	}>
) => {
	const { list, config, count } = action.payload;
	let byCampaign = { ...state.byCampaign };
	let map = { ...state.map };

	let annotationDataIds = list.map((item, i) => {
		map[item._id] = item;
		if (!map[item._id]?.dataObject?.annotate)
			map[item._id].dataObject.annotate = [];
		if (!map[item._id]?.dataObject?.pre) map[item._id].dataObject.pre = [];
		if (!map[item._id]?.dataObject?.tokenMerged)
			map[item._id].dataObject.tokenMerged = {};
		return item._id;
	});

	byCampaign[config.annotationCampaignId] = {
		config,
		list: annotationDataIds,
		count,
	};

	return {
		...state,
		byCampaign,
		map,
		init: true,
	};
};

export const setAllAnnotationData = (
	state: AnnotationDataReducer,
	action: PayloadAction<AnnotationData[]>
) => {
	let map = { ...state.map };

	if (action.payload?.length > 0)
		action.payload.forEach((item: AnnotationData) => {
			map[item._id] = item;
			if (!map[item._id]?.dataObject?.annotate)
				map[item._id].dataObject.annotate = [];
			if (!map[item._id]?.dataObject?.pre)
				map[item._id].dataObject.pre = [];
			if (!map[item._id]?.dataObject?.tokenMerged)
				map[item._id].dataObject.tokenMerged = {};
		});

	return {
		...state,
		map,
	};
};

export const setDataToAnnotate = (
	state: AnnotationDataReducer,
	action: PayloadAction<AnnotationData>
) => {
	let annotate: number[] = [];
	let pre: PreAnnotation = {};

	if (action.payload.dataObject?.annotate) {
		annotate = action.payload.dataObject?.annotate;
	} else annotate = [];

	if (action.payload.dataObject.pre) {
		Object.keys(action.payload.dataObject.pre).forEach((key) => {
			if (typeof action.payload.dataObject.pre[parseInt(key)] == "string")
				action.payload.dataObject.pre[parseInt(key)] = [
					action.payload.dataObject.pre[parseInt(key)] as any,
				];
			pre[parseInt(key)] = action.payload.dataObject.pre[
				parseInt(key)
			].filter((string) => string.length);
			if (!pre[parseInt(key)].length) delete pre[parseInt(key)];
		});
	} else pre = {};

	return {
		...state,
		toAnnotate: {
			...action.payload,
			dataObject: {
				...action.payload.dataObject,
				annotate: annotate,
				pre: pre,
			},
		},
		init: true,
	};
};

export const setAnnotateStore = (
	state: AnnotationDataReducer,
	action: PayloadAction<number[]>
) => {
	return {
		...state,
		toAnnotate: {
			...state.toAnnotate,
			dataObject: {
				...state.toAnnotate.dataObject,
				annotate: action.payload,
			},
		},
	};
};

export const setPreAnnotationStore = (
	state: AnnotationDataReducer,
	action: PayloadAction<PreAnnotation>
) => {
	let pre: PreAnnotation = {};

	if (action.payload) {
		Object.keys(action.payload).forEach((key) => {
			pre[parseInt(key)] = action.payload[parseInt(key)].filter(
				(string) => string.length
			);
			if (!pre[parseInt(key)].length) delete pre[parseInt(key)];
		});
	}
	return {
		...state,
		toAnnotate: {
			...state.toAnnotate,
			dataObject: {
				...state.toAnnotate.dataObject,
				pre: pre,
			},
		},
	};
};

/**
 * if toAnnotate is modified -> reset to default state (from map)
 * @param state
 * @returns
 */
export const resetToAnnotate = (state: AnnotationDataReducer) => {
	const currentToAnnotate = { ...state.toAnnotate };
	const annotationDataMap = { ...state.map };
	const newToAnnotate = annotationDataMap[currentToAnnotate._id];

	let annotate: number[] = [];
	let pre: PreAnnotation = {};

	if (newToAnnotate.dataObject?.annotate) {
		annotate = newToAnnotate.dataObject?.annotate;
	} else annotate = [];

	if (newToAnnotate.dataObject?.pre) {
		pre = newToAnnotate.dataObject?.pre;
	}
	return {
		...state,
		toAnnotate: {
			...newToAnnotate,
			dataObject: {
				...newToAnnotate.dataObject,
				annotate: annotate,
				pre: pre,
			},
		},
	};
};

/**
 * Empty the to annotate data object
 * @param state
 * @returns
 */
export const deleteToAnnotateObject = (state: AnnotationDataReducer) => {
	const toAnnotate = { ...state.toAnnotate };
	const dataObject: ToAnnotateDataObject = {
		items: [],
		annotate: [],
		pre: {},
	};
	return {
		...state,
		toAnnotate: {
			...toAnnotate,
			dataObject,
		},
	};
};

/**
 * Empty all toAnnotate dataObject (trigger all reset)
 * @param state
 * @returns
 */
export const deleteToAnnotate = (state: AnnotationDataReducer) => {
	const toAnnotate = {
		_id: "",
		dataArray: [],
		objectiveCount: 0,
		consensus: 0,
		active: false,
		count: 0,
		dataObject: {
			items: [],
			annotate: [],
			pre: {},
		},
		annotationCampaignId: "",
		annotationItemIds: [],
		typeIdentifier: "",
		createdAt: "",
		updatedAt: "",
	};

	return {
		...state,
		toAnnotate,
	};
};

export const setTokenMergedData = (
	state: AnnotationDataReducer,
	action: PayloadAction<TokenMerged>
) => {
	return {
		...state,
		toAnnotate: {
			...state.toAnnotate,
			dataObject: {
				...state.toAnnotate.dataObject,
				tokenMerged: action.payload,
			},
		},
	};
};

import {
	OntologyMap,
	OntologyReducer,
	KnowledgeCard,
} from "modules/types/ontology";
import { PayloadAction } from "@reduxjs/toolkit";
import { updateKnowledgeCardMap } from "modules/utils/ontology";

export const addKnowledgeCardToOntology = (
	state: OntologyReducer,
	action: PayloadAction<{ knowledgeCard: KnowledgeCard; ontologyId: string }>
) => {
	let ontologyMap: OntologyMap = { ...state.ontologyMap };
	const { knowledgeCard, ontologyId } = action.payload;
	ontologyMap[ontologyId] = updateKnowledgeCardMap(
		ontologyMap[ontologyId],
		knowledgeCard
	);

	return {
		...state,
		ontologyMap,
	};
};

export const setSelectedCardIdsToOntology = (
	state: OntologyReducer,
	action: PayloadAction<{ selectedCardIds: string[]; ontologyId: string }>
) => {
	let ontologyMap: OntologyMap = { ...state.ontologyMap };
	let ontology = { ...ontologyMap[action.payload.ontologyId] };

	ontology.selectedCardIds = [...action.payload.selectedCardIds];
	ontology.selectedPropertyIds = [];
	ontologyMap[action.payload.ontologyId] = ontology;

	return {
		...state,
		ontologyMap,
	};
};

export const updateKnowledgeCards = (
	state: OntologyReducer,
	action: PayloadAction<{ ontologyId: string; list: KnowledgeCard[] }>
) => {
	let ontologyMap: OntologyMap = { ...state.ontologyMap };
	const { ontologyId, list } = action.payload;

	// UPDATE map
	list.forEach((knowledgeCard) => {
		ontologyMap[ontologyId] = updateKnowledgeCardMap(
			ontologyMap[ontologyId],
			knowledgeCard
		);
	});

	return {
		...state,
		ontologyMap,
	};
};

export const deleteKnowledgeCards = (
	state: OntologyReducer,
	action: PayloadAction<{ ontologyId: string; knowledgeCardIds: string[] }>
) => {
	let ontologyMap: OntologyMap = { ...state.ontologyMap };
	const { ontologyId, knowledgeCardIds } = action.payload;

	// UPDATE selected card id
	if (ontologyMap[ontologyId].knowledgeCardMap) {
		const deletedClass =
			ontologyMap[ontologyId].knowledgeCardMap[knowledgeCardIds[0]];
		if (deletedClass) {
			let pathData = deletedClass.path?.split("/");
			let parentToSelect = pathData[pathData?.length - 1];
			ontologyMap[ontologyId].selectedCardIds = [parentToSelect];
		}
	}

	// DELETE knowledge card in ids list (when it will be string[])
	ontologyMap[ontologyId].knowledgeCardIds = ontologyMap[
		ontologyId
	].knowledgeCardIds.filter(
		(classId) => knowledgeCardIds.indexOf(classId) === -1
	);

	// DELETE knowledge card in map
	knowledgeCardIds.forEach((knowledgeCardId) => {
		delete ontologyMap[ontologyId].knowledgeCardMap[knowledgeCardId];
	});

	return {
		...state,
		ontologyMap,
	};
};

export const updateKnowledgeCardSuggestion = (
	state: OntologyReducer,
	action: PayloadAction<{
		knowledgeCard: KnowledgeCard;
		synonyms: string[];
		labelType: string;
	}>
) => {
	const { knowledgeCard, labelType, synonyms } = action.payload;
	const ontologyId = knowledgeCard.knowledgeId;
	let ontologyMap: OntologyMap = { ...state.ontologyMap };

	if (
		!ontologyMap[ontologyId].knowledgeCardMap[knowledgeCard._id].suggestions
	)
		ontologyMap[ontologyId].knowledgeCardMap[
			knowledgeCard._id
		].suggestions = [];

	for (let synonym of synonyms) {
		ontologyMap[ontologyId].knowledgeCardMap[
			knowledgeCard._id
		].suggestions.push({
			type: labelType,
			string: synonym,
			lang: "fr",
		});
	}

	ontologyMap[ontologyId] = updateKnowledgeCardMap(
		ontologyMap[ontologyId],
		ontologyMap[ontologyId].knowledgeCardMap[knowledgeCard._id]
	);

	return {
		...state,
		ontologyMap,
	};
};

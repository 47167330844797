import { addDays, format, formatDistanceToNow, formatDistance } from "date-fns";

export const lastUpdateDate = (date: string) => {
	if (date) return formatDistance(new Date(), new Date(date));
	return date;
};

export const isSameMonth = (d1: Date, d2: Date) => {
	try {
		if (d1.getFullYear() !== d2.getFullYear()) return false;
		if (d1.getMonth() !== d2.getMonth()) return false;
		return true;
	} catch (e) {
		console.error(e);
	}
	return false;
};

export const expiresDate = (date: string) => {
	let year = parseInt(date.split("/")[2]);
	let month = parseInt(date.split("/")[1]);
	let day = parseInt(date.split("/")[0]);
	if (year && month && day)
		return formatDistanceToNow(new Date(year, month, day), {
			addSuffix: true,
		});
	return "";
};

export const formateDate = (date: string, daysToAdd: number = 0) => {
	let v1 = date.split("T")[0];
	let year = parseInt(v1.split("-")[0]);
	let month = parseInt(v1.split("-")[1]) - 1;
	let day = parseInt(v1.split("-")[2]);
	let newDate = addDays(new Date(year, month, day), daysToAdd);
	let result = format(newDate, "dd/MM/yyyy");

	return result;
};

export const datediff = (first: number, second: number) => {
	// Take the difference between the dates and divide by milliseconds per day.
	// Round to nearest whole number to deal with DST.
	return Math.round((second - first) / (1000 * 60 * 60 * 24));
};

// export const isSameMonthLoop = (d1: Date) => {
// 	try {
// 		let now = new Date();
// 		let monthsDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
// 		if ([2020, 2024, 2028, 2032, 2036].indexOf(1900 + now.getYear()) !== -1)
// 			monthsDays[1] = 29;

// 		if (datediff(d1, now) >= monthsDays[d1.getMonth()]) return false;
// 	} catch (e) {
// 		console.error(e);
// 	}
// 	return true;
// };

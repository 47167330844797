import { PayloadAction } from "@reduxjs/toolkit";
import {
	AnnotateConfiguration,
	ConfigurationReducer,
} from "modules/types/annotation-configuration";
import { setNested } from "modules/utils";

/**
 * SET ConfigurationReducer
 * @param state
 * @param action
 * @returns
 */
export const setConfiguration = (
	state: ConfigurationReducer,
	action: PayloadAction<{
		isKeys: boolean;
		configuration: AnnotateConfiguration | { [key: string]: any };
	}>
) => {
	let { isKeys, configuration } = action.payload;
	if (!isKeys)
		return {
			...state,
			annotateConfiguration: configuration as AnnotateConfiguration,
			init: true,
		};

	Object.keys(configuration).forEach((objectKey) => {
		state = setNested(
			state,
			objectKey,
			configuration[objectKey as keyof typeof configuration]
		);
	});
	return state;
};

/**
 * RESET ConfigurationReducer
 * @param state
 * @returns
 */
export const resetConfiguration = (state: ConfigurationReducer) => {
	return {
		...state,
		annotateConfiguration: null,
		init: false,
	};
};

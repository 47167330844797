import { RessourceRealType, RessourceType } from "modules/types/global";
/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "store/store";
import { Group, Member } from "modules/types/group";
import { UserType } from "modules/types/user";

export const resetGroups = (groups: Group[]): AnyAction => {
	return store.dispatch({
		type: "RESET_GROUPS",
		payload: groups,
	});
};

export const setGroups = (groups: Group[]): AnyAction => {
	return store.dispatch({
		type: "SET_GROUPS",
		payload: groups,
	});
};

export const addGroup = (group: Group): AnyAction => {
	return store.dispatch({
		type: "ADD_GROUP",
		payload: group,
	});
};

export const setGroupMembers = (groupId: string, members: Member[]) => {
	return store.dispatch({
		type: "SET_MEMBERS",
		payload: {
			members,
			groupId,
		},
	});
};

export const updateGroup = (group: Group): AnyAction => {
	return store.dispatch({
		type: "UPDATE_GROUP",
		payload: group,
	});
};

export const deleteGroup = (group: Group): AnyAction => {
	return store.dispatch({
		type: "DELETE_GROUP",
		payload: group,
	});
};

export const manageRole = (
	groupId: string,
	member: Member,
	userId: string,
	role: UserType
): AnyAction => {
	return store.dispatch({
		type: "MANAGE_ROLE",
		payload: { groupId, member, userId, role },
	});
};

export const selectGroup = (groupId: string): AnyAction => {
	return store.dispatch({
		type: "SELECT_GROUP",
		payload: groupId,
	});
};

export const addRessourceToGroup = (
	groupId: string,
	groupKey: RessourceType,
	ressource: RessourceRealType
): AnyAction => {
	return store.dispatch({
		type: "ADD_RESSOURCE_TO_GROUP",
		payload: {
			groupId,
			groupKey,
			ressource,
		},
	});
};

export const deleteRessourceFromGroup = (
	groupId: string,
	groupKey: RessourceType,
	ressource: RessourceRealType
): AnyAction => {
	return store.dispatch({
		type: "DELETE_RESSOURCE_FROM_GROUP",
		payload: {
			groupId,
			groupKey,
			ressource,
		},
	});
};

export const deleteGroupMember = (
	groupId: string,
	memberId: string
): AnyAction => {
	return store.dispatch({
		type: "DELETE_MEMBER",
		payload: {
			groupId,
			memberId,
		},
	});
};

import axios, { Method } from "axios";
import { message } from "antd";
import { CognitoUserSession } from "modules/types/user";
import * as cognito from "views/public/auth//libs/cognito";
import { AuthService } from "views/public/auth/services/auth.service";

const statusCodesMessages: { [key: string]: string[] } = {
	201: ["success", "Operation successful"],
	200: ["success", "Operation successful"],
	400: ["error", "Bad request"],
	403: ["warning", "Forbidden Operation"],
	500: ["error", "Error"],
};

const isInterestingRoute = (url: string, method: string) => {
	if (method === "GET") return false;
	if (url.indexOf("read") !== -1) return false;
	if (url.indexOf("retrieve") !== -1) return false;
	if (url.indexOf("get") !== -1) return false;
	if (url.indexOf("refresh") !== -1) return false;
	if (url.indexOf("query") !== -1) return false;
	if (url.indexOf("exist") !== -1) return false;
	if (url.indexOf("annotation-item/create") !== -1) return false;
	if (url.indexOf("auth/refresh") !== -1) return false;
	if (url.indexOf("login") !== -1) return false;
	if (url.indexOf("generate-synonyms") !== -1) return false;
	if (url.indexOf("import-data") !== -1) return false;
	if (url.indexOf("generate-suggestions") !== -1) return false;

	return true;
};

const isInterestingMessage = (message: string) => {
	if (!message) return false;
	if (message?.indexOf("ok") !== -1) return false;
	if (message?.length < 5) return false;
	return true;
};

const cleanMessage = (message: string) => {
	let cleanMessage = message.toLowerCase();
	cleanMessage = `${cleanMessage[0].toUpperCase()}${cleanMessage.slice(1)}`;
	// console.log({ cleanMessage });

	return cleanMessage;
};

export class RequestService {
	/**
	 * Perform request to backend globally
	 * @param url url specification (without base url)
	 * @param method post, get, put ...
	 * @param body data to fetch
	 * @returns response
	 */
	public request = async (
		url: string | undefined,
		method: Method | undefined,
		body: any,
		idToken: string | null = null
	) => {
		if (url && url.indexOf("annotation-group") !== -1) return null;

		let response, error;

		try {
			const token = idToken
				? idToken
				: localStorage.getItem("LettriaIdToken");

			response = await axios({
				url: url,
				method: method,
				data: body,
				headers: {
					Authorization: token
						? ("LettriaIdToken " + token).toString()
						: "Empty",
				},
			});

			await isResponse401(url, method, body, response);
		} catch (e: any) {
			console.error(e);
			response = e?.response;
			error = e;
		}

		const isInteresting = isInterestingRoute(url!, method as string);

		if (
			response &&
			response.status in statusCodesMessages &&
			isInteresting
		) {
			const statusCodeData = statusCodesMessages[response.status];
			if (
				statusCodeData[0] === "success" ||
				statusCodeData[0] === "warning" ||
				statusCodeData[0] === "error"
			) {
				message[statusCodeData[0]]({
					maxCount: 1,
					content: isInterestingMessage(response?.data?.message)
						? cleanMessage(response?.data?.message)
						: statusCodeData[1],
					className: "request-response-message",
				});
			}
		} else if (response && isInteresting) {
			message.info({
				content: `${url} : ${response?.status}`,
				className: "request-response-message",
			});
		}

		if (error) throw error;
		return response?.data;
	};
}

const isResponse401 = async (
	url: string | undefined,
	method: Method | undefined,
	body: any,
	response: any
) => {
	if (response.status === 401) {
		localStorage.removeItem("LettriaIdToken");

		const refreshToken = localStorage.getItem("LettriaRefreshToken");

		if (refreshToken) {
			const tokenResponse: CognitoUserSession =
				await cognito.refreshUserToken(refreshToken);

			if (tokenResponse?.idToken) {
				localStorage.setItem(
					"LettriaIdToken",
					tokenResponse.idToken.jwtToken
				);

				if (tokenResponse?.accessToken)
					localStorage.setItem(
						"LettriaAccessToken",
						tokenResponse.accessToken.jwtToken
					);

				if (tokenResponse?.refreshToken)
					localStorage.setItem(
						"LettriaRefreshToken",
						tokenResponse.refreshToken.token
					);

				response = await axios({
					url: url,
					method: method,
					data: body,
					headers: {
						Authorization: (
							"LettriaIdToken " + tokenResponse.idToken.jwtToken
						).toString(),
					},
				});
			} else {
				await new AuthService().signOut();
			}
		} else {
			await new AuthService().signOut();
		}
	}
};

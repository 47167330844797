import {
	AuthenticationDetails,
	CognitoUser,
	CognitoUserAttribute,
	CognitoUserPool,
	CognitoRefreshToken,
	CognitoUserSession,
} from "amazon-cognito-identity-js";

const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;

const poolData = {
	UserPoolId: `${userPoolId}`,
	ClientId: `${clientId}`,
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

let currentUser: any = userPool.getCurrentUser();

export function getCurrentUser() {
	return currentUser;
}

function getCognitoUser(username: string) {
	const userData = {
		Username: username,
		Pool: userPool,
	};
	const cognitoUser = new CognitoUser(userData);

	return cognitoUser;
}

export async function getSession() {
	if (!currentUser) {
		currentUser = userPool.getCurrentUser();
	}

	return new Promise(function (resolve, reject) {
		currentUser.getSession(function (err: any, session: any) {
			if (err) {
				reject(err);
			} else {
				resolve(session);
			}
		});
	}).catch((err) => {
		throw err;
	});
}

export async function signUpUserWithEmail(
	name: string,
	family_name: string,
	username: string,
	email: string,
	password: string
) {
	console.log("cognito.ts signUpUserWithEmail", {
		username: username,
		email: email,
		password: password,
	});
	return new Promise(function (resolve, reject) {
		const attributeList = [
			new CognitoUserAttribute({
				Name: "email",
				Value: email,
			}),
			new CognitoUserAttribute({
				Name: "name",
				Value: name,
			}),
			new CognitoUserAttribute({
				Name: "family_name",
				Value: family_name,
			}),
		];

		userPool.signUp(
			email,
			password,
			attributeList,
			[],
			function (err, res) {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			}
		);
	}).catch((err) => {
		throw err;
	});
}

export async function verifyCode(username: string, code: string) {
	return new Promise(function (resolve, reject) {
		const cognitoUser = getCognitoUser(username);

		cognitoUser.confirmRegistration(code, true, function (err, result) {
			if (err) {
				reject(err);
			} else {
				resolve(result);
			}
		});
	}).catch((err) => {
		throw err;
	});
}

export async function resendConfirmationCode(username: string) {
	return new Promise(function (resolve, reject) {
		const params = {
			Username: username,
			// Pool: userPool,
			ClientId: clientId as string,
		};
		const cognitoUser = getCognitoUser(username);

		cognitoUser.resendConfirmationCode(function (err, data) {
			if (err) console.log(err, err.stack); // an error occurred
			else console.log(data); // successful response
		}, params);
		// cognitoUser.resendConfirmationCode(userData);
	}).catch((err) => {
		throw err;
	});
}

export async function signInWithEmail(username: string, password: string) {
	return new Promise(function (resolve, reject) {
		const authenticationData = {
			Username: username,
			Password: password,
		};
		console.log("cognito signInWithEmail", { authenticationData });
		const authenticationDetails = new AuthenticationDetails(
			authenticationData
		);
		console.log("username", username);

		currentUser = getCognitoUser(username);

		currentUser.authenticateUser(authenticationDetails, {
			onSuccess: function (res: any) {
				resolve(res);
			},
			onFailure: function (err: any) {
				reject(err);
			},
		});
	}).catch((err) => {
		console.error(err);

		throw err;
	});
}

export function signOut() {
	if (currentUser) {
		currentUser.signOut();
	}
}

export async function refreshUserToken(refreshToken: string): Promise<any> {
	return new Promise(function (resolve, reject) {
		var token = new CognitoRefreshToken({ RefreshToken: refreshToken });
		currentUser.refreshSession(
			token,
			(err: any, session: CognitoUserSession) => {
				if (err) {
					reject(err);
				} else {
					resolve(session);
				}
			}
		);
		// console.log("refreshUserToken", { refreshToken, token });

		// resolve(null);
		// currentUser.getUserAttributes(function (err: any, attributes: any) {
		// 	if (err) {
		// 		reject(err);
		// 	} else {
		// 		resolve(attributes);
		// 	}
		// });
	}).catch((err) => {
		throw err;
	});
}

export async function getAttributes() {
	return new Promise(function (resolve, reject) {
		currentUser.getUserAttributes(function (err: any, attributes: any) {
			if (err) {
				reject(err);
			} else {
				resolve(attributes);
			}
		});
	}).catch((err) => {
		throw err;
	});
}

export async function setAttribute(attribute: any) {
	return new Promise(function (resolve, reject) {
		const attributeList = [];
		const res = new CognitoUserAttribute(attribute);
		attributeList.push(res);

		currentUser.updateAttributes(attributeList, (err: any, res: any) => {
			if (err) {
				reject(err);
			} else {
				resolve(res);
			}
		});
	}).catch((err) => {
		throw err;
	});
}

export async function sendCode(username: string) {
	return new Promise(function (resolve, reject) {
		const cognitoUser = getCognitoUser(username);

		if (!cognitoUser) {
			reject(`could not find ${username}`);
			return;
		}

		cognitoUser.forgotPassword({
			onSuccess: function (res) {
				resolve(res);
			},
			onFailure: function (err) {
				reject(err);
			},
		});
	}).catch((err) => {
		throw err;
	});
}

export async function forgotPassword(
	username: string,
	code: string,
	password: string
) {
	return new Promise(function (resolve, reject) {
		const cognitoUser = getCognitoUser(username);

		if (!cognitoUser) {
			reject(`could not find ${username}`);
			return;
		}

		cognitoUser.confirmPassword(code, password, {
			onSuccess: function () {
				resolve("password updated");
			},
			onFailure: function (err) {
				reject(err);
			},
		});
	});
}

export async function changePassword(oldPassword: string, newPassword: string) {
	return new Promise(function (resolve, reject) {
		currentUser.changePassword(
			oldPassword,
			newPassword,
			function (err: any, res: any) {
				if (err) {
					reject(err);
				} else {
					resolve(res);
				}
			}
		);
	});
}

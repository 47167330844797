export default async () => {
	// const $ = require('jquery')
	// Hard-coded, replace with your public key
	const publicVapidKey =
		"BEgzcMviIjySafn4dOxVj4IVAxEdS-uJsnOUg7Jpu7o42mgMiwyfuyhb0yrFahO-zyDsh8fKvy_QWlvJoLMZHoA";

	if ("serviceWorker" in navigator) {
		run().catch((error) => console.error(error));
	}

	async function run() {
		const swUrl = `${process.env.PUBLIC_URL}/notification-worker.js`;

		const registration = await navigator.serviceWorker.register(swUrl, {
			scope: "/",
		});

		navigator.serviceWorker.ready.then(
			async (serviceWorkerRegistration) => {
				try {
					if (!registration || !registration.pushManager)
						return console.log(
							"No push manager found.. Live notifications wont work !"
						);

					let subscription =
						await registration.pushManager.getSubscription();
					if (!subscription)
						subscription = await registration.pushManager.subscribe(
							{
								userVisibleOnly: true,
								// The `urlBase64ToUint8Array()` function is the same as in
								// https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
								applicationServerKey:
									urlBase64ToUint8Array(publicVapidKey),
							}
						);

					let token = localStorage.getItem("LettriaIdToken");
					await fetch(
						"https://api.app.lettria.com/app/notification/subscribe-push",
						// `${process.env.REACT_APP_API_HOST}notification/subscribe-push`,
						{
							method: "POST",
							body: JSON.stringify(subscription),
							headers: {
								"content-type": "application/json",
								Authorization: "LettriaIdToken " + token,
							},
						}
					);
				} catch (e) {
					console.error(e);
				}

				// console.log('Sent push');
			}
		);
	}

	function urlBase64ToUint8Array(base64String: string) {
		const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding)
			.replace(/\-/g, "+")
			.replace(/_/g, "/");

		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	}
};

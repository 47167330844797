import {
	EditableRessourceType,
	EditableRessourceRealType,
} from "modules/types/global";
import { MembersMap } from "modules/types/group";
import { UserData, UserItems, UserType } from "modules/types/user";

/**
 * Retrieve user Role in item
 * @param user userData from Redux Store
 * @param field Type of item [group, campaigns...]
 * @param item Current item
 * @returns
 */
export const getUserType = (
	user: UserData,
	field: EditableRessourceType,
	item: EditableRessourceRealType
): UserType => {
	try {
		if (!item || !user || !field) return "User";
		// if (user && (user._id === item.owner || user._id === item.author))
		// 	return "Admin";
		// console.log({
		// 	user,

		// 	field,
		// 	item,
		// });
		let userItems: UserItems[] = [];

		if (field === "group") {
			let userType: UserType = "User",
				userItems = user.groupIds;
			if (userItems.length) {
				userItems.forEach((_item: UserItems) => {
					if (_item.id === item._id) {
						userType = _item.userType;
					}
				});
			}
			return userType;
		}

		if (item?.ownerType === "Group") {
			let access = user?.groupIds?.find((group) => {
				return group.id === item.owner;
			});

			return access ? access.userType : "User";
		} else if (item?.ownerType === "User") {
			if (user._id === item.owner) return "Admin";
		}
		return "User";

		// switch (field) {
		// 	case "annotation-campaign":
		// 		userItems = user.annotationCampaignIds;
		// 		break;
		// 	case "dictionary":
		// 		userItems = user.dictionaryIds;
		// 		break;
		// 	case "project":
		// 		userItems = user.projectIds;
		// 		break;
		// 	case "group":
		// 		userItems = user.groupIds;
		// 		break;
		// 	case "dataset":
		// 		userItems = user.dataSetIds;
		// 		break;
		// 	case "ontology":
		// 		userItems = user.ontologyIds;
		// 		break;
		// 	case "pattern":
		// 		userItems = user.patternIds;
		// 		break;
		// }
	} catch (e) {
		console.error(e);
		return "User";
	}
};

export const getRoleGroup = (
	user: UserData,
	groupId: string,
	membersMap: MembersMap
) => {
	const members = membersMap[groupId];
	if (members) {
		const member = members.find((member) => member._id === user._id);
		if (member) return member.role;
	}
	return "User";
};

export const formatUserTypeToCampaignType = (userType: UserType) => {
	switch (userType) {
		case "Admin":
			return "SuperAnnotator";
		case "SuperUser":
			return "SuperAnnotator";
		case "SuperAnnotator":
			return "SuperAnnotator";
		case "Annotator":
			return "Annotator";
		case "User":
			return "Annotator";
	}
};

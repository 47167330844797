import { List, Typography } from "antd";
import { Notification } from "modules/types/notification";
import { lastUpdateDate } from "modules/utils/date";
import { Link } from "react-router-dom";
import { NotificationStatus } from "../../common/components/notification-status/notification-status.component";
import { NotificationProps } from "../../notification.component";

const { Text } = Typography;

export const DatasetImport = (props: NotificationProps) => {
	const getScriptTitle = (notif: Notification) => {
		let actionPrefix = "is importing";
		if (notif.metadata.operationStatus === "SUCCESS")
			actionPrefix = "imported successfully";
		if (notif.metadata.operationStatus === "FAILED")
			actionPrefix = "failed to import";

		return (
			<Text>
				<NotificationStatus {...props} />
				Dataset{" "}
				<Link
					to={`/nlp/dataset/view/${notif.metadata?.ressourceIds?.[0]}`}
				>
					{notif.metadata.ressourceName}
				</Link>{" "}
				{actionPrefix}
			</Text>
		);
	};

	return (
		<List.Item
			className={
				(props.unreadNotification ? "un" : "") + "read-notification"
			}
		>
			<List.Item.Meta
				title={getScriptTitle(props.notification)}
				description={lastUpdateDate(props.notification.createdAt!)}
			/>
		</List.Item>
	);
};

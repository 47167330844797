import { PayloadAction } from "@reduxjs/toolkit";
import {
	AnnotateData,
	ConfigurationReducer,
} from "modules/types/annotation-configuration";

export const setSelectedIndexesStore = (
	state: ConfigurationReducer,
	action: PayloadAction<number[]>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				data: {
					...state.annotateConfiguration?.data,
					selectedIndexes: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setMultiSelectionStore = (
	state: ConfigurationReducer,
	action: PayloadAction<boolean>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				data: {
					...state.annotateConfiguration?.data,
					multiSelection: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setAnnotationDataIdStore = (
	state: ConfigurationReducer,
	action: PayloadAction<string>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				data: {
					...state.annotateConfiguration?.data,
					annotationDataId: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setAnnotateDataStore = (
	state: ConfigurationReducer,
	action: PayloadAction<AnnotateData>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				data: {
					...state.annotateConfiguration?.data,
					annotateData: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setToAnnotateStore = (
	state: ConfigurationReducer,
	action: PayloadAction<number[]>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				data: {
					...state.annotateConfiguration?.data,
					toAnnotate: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

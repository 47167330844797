/* eslint-disable jsx-a11y/alt-text */
import { Divider, Space, Tooltip } from "antd";
import { AuthStatus } from "./components/user/user-auth-status/user-auth-status.component";
import NotificationManager from "./components/notification/notification.component";
import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import "./styles/header.style.less";
import MainMenu from "./components/main-menu/main-menu.component";
import { useEffect, useState } from "react";
import { SelectOutlined } from "@ant-design/icons";

const HeaderComponent = (props: HeaderComponentProps) => {
	const [isNotified, setIsNotified] = useState(false);
	useEffect(() => {
		setIsNotified(
			(localStorage.getItem("lettria-new-app-contruction-send-mail-1") &&
				true) as boolean
		);
		localStorage.setItem("lettria-new-app-contruction-send-mail-1", "true");
	}, []);

	return (
		<header
			id="app-header"
			style={{
				height: "47px",
				background: "#0b2540",
				position: "fixed",
				width: "100%",
				zIndex: "999",
				display: "block",
				verticalAlign: "middle",
				alignItems: "center",
				padding: "0 50px",
				paddingTop: "8px",
			}}>
			<a
				className="banner"
				href="mailto:contact@lettria.com"
				style={isNotified ? { display: "none" } : {}}>
				<Space>
					<div style={{ fontSize: "34px" }}>🚧</div>
					<Divider type="vertical" />
					<div>
						<strong style={{ fontSize: "16px" }}>
							Our new app version is still under construction.
						</strong>
						<br />
						If you encounter any problem, send us a mail at{" "}
						<strong>contact@lettria.com</strong>
					</div>
				</Space>
			</a>
			<Space>
				<Link to={`/`}>
					<img
						alt=""
						src="/images/logo.svg"
						style={{
							display: "block",
							opacity: 0.9,
							marginTop: "-2px",
						}}
					/>
				</Link>
				<MainMenu />
			</Space>
			<Space size="middle" style={{ float: "right" }}>
				<a
					style={{ color: "#fff" }}
					target="_blank"
					href="https://lettria.com/documentation/platform/getting-started"
					rel="noreferrer">
					<Space>
						<SelectOutlined />
						Documentation
					</Space>
				</a>
				<NotificationManager />
				<AuthStatus />
			</Space>
		</header>
	);
};

const mapState = (state: RootState) => {
	return {
		workspace: state.workspace,
	};
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type HeaderComponentProps = PropsFromRedux;

export default connector(HeaderComponent);

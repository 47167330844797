import { Group } from "modules/types/group";
import { setGroups } from "store/group/group-actions";
import { setProjects } from "store/project/project-actions";
import { Project } from "modules/types/project";
import { ClassProperty, KnowledgeCard, Ontology } from "modules/types/ontology";
import {
	setClassProperties,
	setOntologies,
	setProjectOntologies,
	updateKnowledgeCards,
} from "store/ontology/actions";
import { setDatasets } from "store/dataset/dataset-actions";
import { Dataset } from "modules/types/dataset";
import { Pattern } from "modules/types/pattern";
import { setPatterns } from "store/pattern/pattern-actions";
import { Dictionary } from "modules/types/dictionary";
import { setDictionaries } from "store/dictionary/dictionary-actions";
import { Campaign } from "modules/types/annotation-campaign";
import {
	BodyElement,
	RessourceRealType,
	RessourceType,
} from "modules/types/global";
import { setCampaigns } from "store/annotation-campaign/annotation-campaign-actions";
import { RetrieveRessourceApi } from "../api/retrieve-ressource.api.service";
import { formatProject } from "modules/utils/project";
import { setFileStorages } from "store/file-storage/file-storage-actions";
import { FileStorage } from "modules/types/file-storage";

export class RetrieveRessourceStore extends RetrieveRessourceApi {
	/**
	 *
	 * @param ressourceType
	 * @param body
	 * @returns
	 */
	public retrieveRessourcesStore = async (
		ressourceType: RessourceType,
		body: BodyElement
	) => {
		const { documents, count } = await this.retrieveRessourcesApi(
			ressourceType,
			body
		);
		if (documents.length) {
			this.setDocuments(ressourceType, documents);
			return { documents, count };
		}
		return false;
	};
	private setDocuments = (
		ressourceType: RessourceType,
		documents: RessourceRealType[]
	) => {
		switch (ressourceType) {
			case "annotation-campaign":
				setCampaigns(documents as Campaign[]);
				break;
			case "class-property":
				setClassProperties(documents as ClassProperty[]);
				break;
			case "dataset":
				setDatasets(documents as Dataset[]);
				break;
			case "dictionary":
				setDictionaries(documents as Dictionary[]);
				break;
			case "file-storage":
				setFileStorages(documents as FileStorage[]);
				break;
			case "group":
				setGroups(documents as Group[]);
				break;
			case "knowledge-card":
				if (documents.length) {
					updateKnowledgeCards(
						(documents as KnowledgeCard[])[0].knowledgeId,
						documents as KnowledgeCard[]
					);
				}
				break;
			case "ontology":
				setOntologies(documents as Ontology[]);
				break;
			case "pattern":
				setPatterns(documents as Pattern[]);
				break;
			case "project":
				setProjects(
					(documents as Project[]).map((_project) => {
						const { ontology, project } = formatProject(_project);
						if (ontology) setProjectOntologies([ontology]);
						return project;
					})
				);
				break;
		}
	};
}

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {
	Form,
	Row,
	Col,
	Input,
	Tooltip,
	Space,
	Switch,
	Button,
	Typography,
	Divider,
} from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { AuthService } from "views/public/auth/services/auth.service";
import {
	useValidCode,
	useValidPassword,
	useValidUsername,
} from "views/public/auth/hooks/useAuthHooks";

export interface Credentials {
	name: string | null;
	lastName: string | null;
	email: string | null;
	username: string | null;
	password: string | null;
	confirmPassword: string | null;
}

export const ResetPasswordForm = (props: any) => {
	const authService: AuthService = new AuthService();

	const { code, setCode } = useValidCode("");
	const { password, setPassword } = useValidPassword("");
	// const { username, setUsername, usernameIsValid } = useValidUsername("");

	const [codeIsSend, setCodeIsSend] = useState(false);

	const [cognitorErrorMessage, setCognitorErrorMessage] = useState<
		string | null
	>(null);
	const [codeErrorMessage, setCodeErrorMessage] = useState<string | null>(
		null
	);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);

	const navigate = useNavigate();

	const pattern = new RegExp(/^[0-9]{6}$/i);

	const resetPassword = async () => {
		if (!isSubmit) {
			setIsSubmit(true);
		}
		if (!pattern.test(code)) {
			return setCodeErrorMessage(
				"Please enter a valid code (6 digits) or request a code again "
			);
		}
		try {
			await authService.forgotPassword(props.username, code, password);
			navigate("login");
		} catch (err: any) {
			setCognitorErrorMessage(err.message);
		}
	};

	const resetPasswordFailed = (errorInfo: ValidateErrorEntity) => {
		if (!pattern.test(code)) {
			return setCodeErrorMessage(
				"Please enter a valid code (6 digits) or request a code again "
			);
		}
	};

	const handleFormChange = () => {
		setIsSubmit(false);
		setCognitorErrorMessage(null);
		setCodeErrorMessage(null);
	};

	return (
		<>
			<Row justify="center">
				<Col span={24}>
					<Typography.Title level={2}>
						Reset Password
					</Typography.Title>
				</Col>
			</Row>
			<Row justify="center">
				<Col span={24}>
					<Form
						name="basic"
						initialValues={{ remember: true }}
						onChange={handleFormChange}
						onFinishFailed={resetPasswordFailed}
						onFinish={resetPassword}
					>
						<Form.Item name="code">
							<Input
								className="inputs"
								prefix={
									<LockOutlined className="site-form-item-icon" />
								}
								placeholder="Code"
								onChange={(e) => setCode(e.target.value)}
							/>
							{cognitorErrorMessage ? (
								<p style={{ color: "#ff4d4f" }}>
									{cognitorErrorMessage}
								</p>
							) : null}
							{codeErrorMessage ? (
								<p style={{ color: "#ff4d4f" }}>
									{codeErrorMessage}
								</p>
							) : null}
						</Form.Item>
						<Form.Item
							name="password"
							rules={[
								() => ({
									validator(_, value) {
										const pattern = new RegExp(
											"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
										);
										if (!isSubmit) {
											return Promise.resolve();
										}
										if (pattern.test(value)) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"Your password does not respect our rules"
											)
										);
									},
								}),
							]}
						>
							<Input.Password
								className="inputs"
								prefix={
									<LockOutlined className="site-form-item-icon" />
								}
								placeholder="Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Item>
						<Form.Item
							name="confirmPassword"
							rules={[
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!isSubmit) {
											return Promise.resolve();
										} else if (
											getFieldValue("password") === value
										) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												!value
													? "Confirm password is required"
													: "The two passwords that you entered do not match!"
											)
										);
									},
								}),
							]}
						>
							<Input.Password
								className="inputs"
								prefix={
									<LockOutlined className="site-form-item-icon" />
								}
								placeholder="Confirm Password"
							/>
						</Form.Item>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								onClick={() => setIsSubmit(true)}
								className="forgot-form-button"
							>
								Reset Password
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>

			<Row justify="center">
				<Col span={24}>
					<Divider />
				</Col>
			</Row>
			<Row justify="center">
				<Col span={24}>
					<Space>
						<Typography.Text>
							Already have an account?
						</Typography.Text>
						<Link to="/login">
							<Typography.Link>Login</Typography.Link>
						</Link>
					</Space>
				</Col>
			</Row>
		</>
	);
};

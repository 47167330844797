/*eslint no-unused-vars:0*/
import { store } from "store/store";
import { AnyAction } from "@reduxjs/toolkit";
import { KnowledgeCard, PropertyValue } from "modules/types/ontology";

export const addKnowledgeCardToOntology = (
	knowledgeCard: KnowledgeCard,
	ontologyId: string
): AnyAction => {
	return store.dispatch({
		type: "ADD_KNOWLEDGE_CARD_TO_ONTOLOGY",
		payload: { knowledgeCard, ontologyId },
	});
};

export const setSelectedCardIdsToOntology = (
	selectedCardIds: string[],
	ontologyId: string
): AnyAction => {
	return store.dispatch({
		type: "SET_SELECTED_CARD_TO_ONTOLOGY",
		payload: { selectedCardIds, ontologyId },
	});
};

export const updateKnowledgeCards = (
	ontologyId: string,
	list: KnowledgeCard[]
): AnyAction => {
	return store.dispatch({
		type: "UPDATE_KNOWLEDGE_CARDS",
		payload: { ontologyId, list },
	});
};

export const deleteKnowledgeCards = (
	ontologyId: string,
	knowledgeCardIds: string[]
): AnyAction => {
	return store.dispatch({
		type: "DELETE_KNOWLEDGE_CARDS",
		payload: { ontologyId, knowledgeCardIds },
	});
};

export const updateKnowledgeCardSuggestion = (
	knowledgeCard: KnowledgeCard,
	labelType: string,
	synonyms: string[]
): AnyAction => {
	return store.dispatch({
		type: "UPDATE_KNOWLEDGE_CARD_SUGGESTIONS",
		payload: { knowledgeCard, labelType, synonyms },
	});
};

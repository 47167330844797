/**
 * Rewrite string in fucntion of the limit
 * @param string
 * @param limit
 * @returns
 */
export const limitStringLength = (string: string, limit: number) => {
	if (string && string.length > limit)
		return string.substring(0, limit >= 2 ? limit - 2 : 1) + " ...";
	return string;
};

/**
 * experimental
 * @param string
 * @returns
 */
export const removePluralString = (string: string) => {
	if (string.toLowerCase().charAt(string.length - 1) === "s")
		return string.toLowerCase().slice(0, -1);
	else if (string.toLowerCase().slice(-4) === "eaux")
		return string.toLowerCase().slice(0, -1);
	else if (string.length > 3 && string.toLowerCase().slice(-3) === "aux")
		return string.slice(0, -3).concat("al");
	return string;
};

/**
 * Normalize string
 * @param str
 * @returns
 */
export const getNormalizedString = (str: string) => {
	str = str.replace(/\s+/g, " ").trim();
	return str ? removeDiacritics(str.toLowerCase()) : "";
};

/**
 * Remove accent (use  *getNormalizedString*  instead)
 * @param str
 * @returns
 */
export const removeDiacritics = (str: string) => {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

/**
 * Copy *text* to clipboard
 * @param text to copy
 */
export const copyToClipboard = (text: string) => {
	const selBox = document.createElement("textarea");
	selBox.style.position = "fixed";
	selBox.style.left = "0";
	selBox.style.top = "0";
	selBox.style.opacity = "0";
	selBox.value = text;
	document.body.appendChild(selBox);
	selBox.focus();
	selBox.select();
	document.execCommand("copy");
	document.body.removeChild(selBox);
};

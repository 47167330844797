export const getNested = function (obj: any, key: string) {
	return key
		.split(".")
		.reduce(
			(o, x) => (typeof o === "undefined" || o === null ? o : o[x]),
			obj
		);
};

export const setNested = function (obj: any, key: any, value: any) {
	let keys = key.split("."),
		curs = obj;
	try {
		for (var i = 0; i < keys.length - 1; i++) {
			if (keys[i]) curs = curs[keys[i]];
		}
		curs[keys[keys.length - 1]] = value;
	} catch (e) {
		console.error(e);
	}
	return obj;
};

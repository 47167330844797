import {
	EditableRessourceRealType,
	EditableRessourceType,
	PageParams,
	BodyElement,
} from "modules/types/global";
import { formatRoute } from "modules/utils/ressource";
import { RequestService } from "services/request.service";

type ReadResponse = {
	count: number;
	documents: EditableRessourceRealType[];
};

export class SearchListApi extends RequestService {
	/**
	 *
	 * @param ressourceType
	 * @param urlParams
	 * @param body
	 * @returns
	 */
	protected retrieveRessourcesPaginatedApi = async (
		ressourceType: EditableRessourceType,
		urlParams: PageParams,
		body: BodyElement
	): Promise<ReadResponse> =>
		await this.request(
			`${formatRoute(ressourceType)}/read?skip=${urlParams.skip}&limit=${
				urlParams.limit
			}&query=${urlParams.query}`,
			"POST",
			body
		);
}

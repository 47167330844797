export const colors = [
	"#E4A951",
	"#51C1E4",
	"#53B625",
	"#C23478",
	"#6343E4",
	"#00FFFF",
	"#58ff47",
	"#B69AFF",
	"#6700F9",
	"#4C006F",
	"#00816C",
	"#72FF9B",
	"#6A6C00",
	"#E2C88C",
	"#63C2FE",
	"#DA0084",
	"#593200",
	"#40BDAB",
	"#B49B00",
	"#F200FF",
	"#930000",
	"#004E74",
	"#f31138",
	"#e68739",
	"#dcc391",
	"#417c5b",
	"#226448",
	"#3D4B50",
	"#481E35",
	"#7B7FC8",
	"#91BDB7",
	"#E6D6BE",
	"#503726",
	"#9C3950",
	"#AB5D73",
	"#7D7B7A",
	"#577EA4",
	"#293048",
	"#2BA333",
	"#13386B",
	"#9D4529",
	"#97DB5C",
	"#434766",
	"#1F0F11",
	"grey",
];

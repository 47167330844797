import { PayloadAction } from "@reduxjs/toolkit";
import {
	ConfigurationReducer,
	ViewerMode,
} from "modules/types/annotation-configuration";

export const setViewerModeStore = (
	state: ConfigurationReducer,
	action: PayloadAction<ViewerMode>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				component: {
					...state.annotateConfiguration?.component,
					viewerMode: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setAnnotateIsEmptyStore = (
	state: ConfigurationReducer,
	action: PayloadAction<boolean>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				component: {
					...state.annotateConfiguration?.component,
					annotateIsEmpty: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setIsFetchingStore = (
	state: ConfigurationReducer,
	action: PayloadAction<boolean>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				component: {
					...state.annotateConfiguration?.component,
					isFetching: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setSaveButtonClassStore = (
	state: ConfigurationReducer,
	action: PayloadAction<string>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				component: {
					...state.annotateConfiguration?.component,
					saveButtonClass: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setStartAnnotatingStore = (
	state: ConfigurationReducer,
	action: PayloadAction<Date>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				component: {
					...state.annotateConfiguration?.component,
					startAnnotating: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

export const setUncompleteRelationships = (
	state: ConfigurationReducer,
	action: PayloadAction<number[]>
) => {
	if (state.annotateConfiguration) {
		return {
			...state,
			annotateConfiguration: {
				...state.annotateConfiguration,
				component: {
					...state.annotateConfiguration?.component,
					uncompleteRelationships: action.payload,
				},
			},
		};
	} else {
		return {
			...state,
		};
	}
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { Divider, Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { RootState } from "store/store";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import "./styles/main-menu.style.less";
import { EditableRessourceType } from "modules/types/global";
import { getPathFromRessource } from "modules/utils/ressource";
import { MenuTabLink } from "./components/menu-tab-link/menu-tab-link.component";
import { SearchList } from "./components/search-list/search-list.component";

const styleButton = {
	borderColor: "transparent",
	borderRadius: "1ch",
	color: "white",
	fontSize: "14px",
};

const activeButton = {
	background: "rgba(255, 255, 255, 0.33)",
};

const outButton = {
	background: "#0b2540",
};

const hoverButton = {
	background: "rgba(255, 255, 255, 0.15)",
};

export const useOutsideAlerter = (ref: any, onOutside: Function) => {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			let headerElem = document.getElementById("app-header");
			if (
				ref.current &&
				!ref.current.contains(event.target) &&
				(!headerElem?.contains || !headerElem?.contains(event.target))
			) {
				onOutside();
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
};

type MainMenuOwnProps = {};

const MainMenu = (props: MainMenuProps) => {
	const wrapperRef = useRef(null);
	const [hover, setHover] = useState(false);
	const [ressourceType, setEditableRessourceType] =
		useState<EditableRessourceType>("project");
	const [isActive, setIsActive] = useState(false);

	const onOutside = () => {
		setIsActive(false);
	};

	const toggleMenu = (e: any) => {
		setIsActive(!isActive);
		e.preventDefault();
		e.stopPropagation();
	};

	const ressourceTypePluralName = (name: EditableRessourceType) => {
		switch (name) {
			case "ontology":
				return "ontologies";
			case "dictionary":
				return "dictionaries";
			case "annotation-campaign":
				return "campaigns";
			case "file-storage":
				return "files";
			default:
				return name + "s";
		}
	};
	const ressourceTypeName = (name: EditableRessourceType) => {
		switch (name) {
			case "annotation-campaign":
				return "campaign";
			case "file-storage":
				return "file";
			default:
				return name;
		}
	};

	useOutsideAlerter(wrapperRef, onOutside);

	const menu = (
		<div className="menu-wrapper-container" ref={wrapperRef}>
			<div className="col-container">
				<div className="col-left">
					<div className="tab-links-list">
						<MenuTabLink
							name="Projects"
							value="project"
							setEditableRessourceType={setEditableRessourceType}
							ressourceType={ressourceType}
						/>
						<MenuTabLink
							name="Datasets"
							value="dataset"
							setEditableRessourceType={setEditableRessourceType}
							ressourceType={ressourceType}
						/>
						<MenuTabLink
							name="Dictionaries"
							value="dictionary"
							setEditableRessourceType={setEditableRessourceType}
							ressourceType={ressourceType}
						/>
						<MenuTabLink
							name="Groups"
							value="group"
							setEditableRessourceType={setEditableRessourceType}
							ressourceType={ressourceType}
						/>
						<MenuTabLink
							name="Campaigns"
							value="annotation-campaign"
							setEditableRessourceType={setEditableRessourceType}
							ressourceType={ressourceType}
						/>

						<MenuTabLink
							name="Ontology"
							value="ontology"
							setEditableRessourceType={setEditableRessourceType}
							ressourceType={ressourceType}
						/>
						<MenuTabLink
							name="Patterns"
							value="pattern"
							setEditableRessourceType={setEditableRessourceType}
							ressourceType={ressourceType}
						/>
					</div>
				</div>
				<div className="col-right">
					<SearchList
						ressourceType={ressourceType}
						setIsActive={setIsActive}
						isActive={isActive}
					/>
					<Divider style={{ margin: "12px 0" }} />
					<div className="bottom-actions">
						<div className="action-item">
							<Link
								onClick={() => setIsActive(false)}
								to={getPathFromRessource(ressourceType)}
							>
								All {ressourceTypePluralName(ressourceType)}
							</Link>
						</div>
						{ressourceType !== "annotation-campaign" ? (
							<div className="action-item">
								<Link
									onClick={() => setIsActive(false)}
									to={
										getPathFromRessource(ressourceType) +
										`create`
									}
								>
									Create {ressourceTypeName(ressourceType)}
								</Link>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<Dropdown
			overlay={menu}
			placement="bottomLeft"
			arrow
			open={isActive}
			trigger={[]}
		>
			<Button
				onClick={toggleMenu}
				onMouseEnter={() => {
					setHover(true);
				}}
				onMouseLeave={() => {
					setHover(false);
				}}
				style={{
					...styleButton,
					...(hover ? hoverButton : outButton),
					...(isActive ? activeButton : {}),
				}}
			>
				Menu <DownOutlined />
			</Button>
		</Dropdown>
	);
};

const mapState = (state: RootState) => {
	return state;
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MainMenuProps = PropsFromRedux & MainMenuOwnProps;

export default connector(MainMenu);

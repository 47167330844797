import { AnyAction } from "@reduxjs/toolkit";
import {
	addAnnotationData,
	deleteAnnotationDataByCampaign,
	deleteToAnnotate,
	deleteToAnnotateObject,
	resetToAnnotate,
	setAllAnnotationData,
	setAnnotateStore,
	setAnnotationDataByCampaign,
	setDataToAnnotate,
	setPreAnnotationStore,
	setTokenMergedData,
} from "./annotation-data-methods";
import {
	AnnotationDataActionType,
	ByCampaignActionType,
	AnnotationDataListActionType,
	PreAnnotationActionType,
} from "modules/types/annotation-data/store";
import {
	NumbersActionType,
	StringActionType,
} from "modules/types/global/store";
import { AnnotationDataReducer } from "modules/types/annotation-data";

const initialState: AnnotationDataReducer = {
	init: false,
	byCampaign: {},
	map: {},
	toAnnotate: {
		_id: "",
		dataArray: [],
		objectiveCount: 0,
		consensus: 0,
		active: false,
		count: 0,
		dataObject: {
			items: [],
			annotate: [],
			pre: {},
		},
		annotationCampaignId: "",
		annotationItemIds: [],
		typeIdentifier: "",
		createdAt: "",
		updatedAt: "",
	},
};

export const annotationDataReducer = (
	state = initialState,
	action: AnyAction
): AnnotationDataReducer => {
	switch (action.type) {
		case "ADD_ANNOTATION_DATA": {
			return addAnnotationData(state, action as AnnotationDataActionType);
		}
		case "DELETE_ANNOTATION_DATA_BY_CAMPAIGN": {
			return deleteAnnotationDataByCampaign(
				state,
				action as StringActionType
			);
		}
		case "SET_ANNOTATION_DATA_BY_CAMPAIGN": {
			return setAnnotationDataByCampaign(
				state,
				action as ByCampaignActionType
			);
		}
		case "SET_ALL_ANNOTATION_DATA": {
			return setAllAnnotationData(
				state,
				action as AnnotationDataListActionType
			);
		}
		case "SET_DATA_TO_ANNOTATE": {
			return setDataToAnnotate(state, action as AnnotationDataActionType);
		}
		case "SET_ANNOTATE": {
			return setAnnotateStore(state, action as NumbersActionType);
		}
		case "SET_PRE_ANNOTATION": {
			return setPreAnnotationStore(
				state,
				action as PreAnnotationActionType
			);
		}
		case "RESET_TO_ANNOTATE": {
			return resetToAnnotate(state);
		}
		case "DELETE_TO_ANNOTATE_OBJECT": {
			return deleteToAnnotateObject(state);
		}
		case "DELETE_TO_ANNOTATE": {
			return deleteToAnnotate(state);
		}
		case "SET_TOKEN_MERGED_DATA": {
			return setTokenMergedData(state, action as any);
		}
		default: {
			break;
		}
	}
	return state;
};

export default annotationDataReducer;

import {
	KnowledgeCard,
	KnowledgeCardMap,
	KnowledgeCardTree,
} from "modules/types/ontology";
import { SuggestionMap } from "modules/types/suggestion";

/**
 * Create knowledge card tree from knowledge cards path
 * @param _knowledgeCardMap
 * @returns
 */
export const createClassTree = (_knowledgeCardMap: KnowledgeCardMap) => {
	let map: any = {};
	map = JSON.parse(JSON.stringify(_knowledgeCardMap));
	let tree: KnowledgeCardTree[] = [];
	const knowledgeCards = Object.values(
		JSON.parse(JSON.stringify(_knowledgeCardMap)) as KnowledgeCardMap
	).sort(
		(a: KnowledgeCard, b: KnowledgeCard) => b.path.length - a.path.length
	);

	knowledgeCards.forEach((card) => {
		try {
			map[card._id].title = map[card._id].name;
			if (!map[card._id].children) map[card._id].children = [];

			const ids = card.path.split("/").filter((c) => c);

			if (ids.length) {
				map[card._id].key =
					map[card._id].path + "/" + map[card._id]._id;

				if (
					map[ids[ids.length - 1]] &&
					!map[ids[ids.length - 1]]?.children
				)
					// if (map[ids[ids.length - 1]])
					map[ids[ids.length - 1]].children = [];

				if (
					map[ids[ids.length - 1]].children
						.map((c: any) => c._id)
						.indexOf(map[card._id]._id) === -1
				)
					map[ids[ids.length - 1]].children = [
						...map[ids[ids.length - 1]].children,
						map[card._id],
					];

				if (map[card._id].path !== "/") delete map[card._id];
			} else {
				map[card._id].key = "/" + map[card._id]._id;
			}
		} catch (error) {
			console.error(error);
		}
	});
	tree = Object.values(map);
	return tree;
};

/**
 * Create Knowledge Card list from Knowledge Card tree
 * @param tree Cards tree from ontology-hierarchy
 * @returns Card list generated
 */
export const formatKnowledgeCardTreeToList = (
	tree: KnowledgeCardTree[],
	arr: { _id: string; path: string }[] = []
) => {
	for (let item of tree) {
		let ids = item.key.split("/");
		let path = "";

		for (let i = 1; i < ids.length - 1; i++) path += "/" + ids[i];
		if (!path) path += "/";
		arr.push({
			_id: ids[ids.length - 1],
			path: path,
		});
		if (item.children) formatKnowledgeCardTreeToList(item.children, arr);
	}
	return arr;
};

/**
 * Update path Card for dragObj and its children
 * @param path parent path
 * @param dragObj the object you're dragging
 * @returns dragObj with updated path
 */
export const updateKnowledgeCardTreePath = (
	path: string,
	dragObj: KnowledgeCardTree
) => {
	const ids = dragObj.key.split("/");
	const id = ids[ids.length - 1];
	dragObj.key = path + "/" + id;
	if (dragObj.children.length)
		for (let item of dragObj.children)
			updateKnowledgeCardTreePath(dragObj.key, item);
	return dragObj;
};

/**
 * Retrieve parent path from key attribute
 * @param path item key
 * @returns parent path (w/o item id)
 */
export const getParentPath = (path: string) => {
	let newPath = "";
	let ids = path.split("/");
	for (let i = 1; i < ids.length - 1; i++) newPath += "/" + ids[i];
	return newPath;
};

/**
 * Retrieve item id from its key attribute
 * @param key item key (including item id)
 * @returns item id
 */
export const getIdFromKey = (key: string) => {
	if (!key) return "";
	const ids = key.split("/");
	return ids[ids.length - 1];
};

/**
 * Retrieve item id from its key attribute
 * @param path item key (including item id)
 * @returns item id
 */
export const getIdsListFromPath = (path: string) => {
	let ids = path.split("/");
	return ids.filter((id) => id !== "");
};

/**
 * Retrieve all ids from item and its children
 * @param item KnowledgeCard item
 * @param ids default array that will be filled with ids
 * @returns array with all ids and item id ata first position
 */
export const getChildrenIdsFromItem = (
	item: KnowledgeCardTree,
	ids: string[] = []
) => {
	ids.push(getIdFromKey(item.key));
	if (item.children.length)
		for (let _item of item.children) getChildrenIdsFromItem(_item, ids);
	return ids;
};

/**
 * Retrieve all ids from itemId and its children
 * @param itemId
 * @param knowledgeCards
 * @returns
 */
export const getChildrenIdsFromItemId = (
	itemId: string,
	knowledgeCards: KnowledgeCardMap
) => {
	let _knowledgeCards: KnowledgeCard[] = [],
		itemCard = knowledgeCards[itemId] ? knowledgeCards[itemId] : null;
	if (itemCard) {
		let itemCards = Object.values(knowledgeCards).filter(
			(card) => card.path.indexOf(itemId) !== -1
		);
		if (itemCards.length) _knowledgeCards = [...itemCards, itemCard];
		else _knowledgeCards = [itemCard];
	}
	return _knowledgeCards.map((card) => card._id);
};

/**
 * Sort the knowledge card tree alphabetically
 * @param knowledgeCardsTree Global tree
 * @param order true = croissant | false = decroissant
 * @returns
 */
export const sortClassAlphabetical = (
	knowledgeCardsTree: KnowledgeCardTree[],
	order = true
) => {
	knowledgeCardsTree.sort((a, b) =>
		order ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)
	);
	knowledgeCardsTree.forEach((knowledgeCardTree) => {
		if (knowledgeCardTree.children.length) {
			sortClassAlphabetical(knowledgeCardTree.children, order);
		}
	});
	return knowledgeCardsTree;
};

/**
 * Sort the knowledge card tree by creation date
 * @param knowledgeCardsTree Global tree
 * @param order true = croissant | false = decroissant
 * @returns
 */
export const sortClassCreatedAt = (
	knowledgeCardsTree: KnowledgeCardTree[],
	order = true
) => {
	knowledgeCardsTree.sort((a, b) =>
		order
			? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
			: new Date(a.createdAt).getTime() + new Date(b.createdAt).getTime()
	);
	knowledgeCardsTree.forEach((knowledgeCardTree) => {
		if (knowledgeCardTree.children.length) {
			sortClassCreatedAt(knowledgeCardTree.children, order);
		}
	});
	return knowledgeCardsTree;
};

/**
 * Return default knowledge-card on first level of hierarchy
 * @param knowledgeCards
 * @returns
 */
export const getDefaultKnowledgeCard = (knowledgeCards: KnowledgeCard[]) => {
	return knowledgeCards
		.filter((card) => card.path === "/")
		.sort((a, b) =>
			a.name.toLowerCase().localeCompare(b.name.toLowerCase())
		)[0];
};

/**
 * Return knowledge-card from its key
 * @param key
 * @param knowledgeCards
 * @returns
 */
export const getKnowledgeCardFromKey = (
	key: string,
	knowledgeCards: KnowledgeCardMap
) => {
	return knowledgeCards[getIdFromKey(key)]
		? knowledgeCards[getIdFromKey(key)]
		: null;
};

/**
 * Remove suggestion from the knowledge-card in param
 * @param knowledgeCard
 * @returns
 */
export const removeUsedSuggestions = (knowledgeCard: KnowledgeCard) => {
	if (knowledgeCard?.suggestions?.pref?.length) {
		let prefLabels = knowledgeCard.prefLabel?.map((_elem) => _elem?.string);
		knowledgeCard.suggestions.pref =
			knowledgeCard?.suggestions?.pref.filter(
				(_elem: string) => prefLabels?.indexOf(_elem) === -1
			);
	}
	if (knowledgeCard?.suggestions?.alt?.length) {
		let altLabels = knowledgeCard?.altLabel?.map((_elem) => _elem?.string);
		knowledgeCard.suggestions.alt = knowledgeCard?.suggestions?.alt.filter(
			(_elem: string) => altLabels?.indexOf(_elem) === -1
		);
	}
	if (knowledgeCard?.suggestions?.hidden?.length) {
		let hiddenLabels = knowledgeCard?.hiddenLabel?.map(
			(_elem) => _elem?.string
		);
		knowledgeCard.suggestions.hidden =
			knowledgeCard?.suggestions?.hidden.filter(
				(_elem: string) => hiddenLabels?.indexOf(_elem) === -1
			);
	}
	return knowledgeCard;
};

/**
 *
 * @param knowledgeCardSuggestions
 * @param discardedSuggestions
 * @returns
 */
export const blockDiscardedSuggestions = (
	knowledgeCardSuggestions: any[],
	discardedSuggestions: any[] | undefined
) => {
	const getSuggestionKey = (_elem: any) =>
		"_" + _elem?.type + _elem?.string + "_" + _elem?.lang;

	if (!knowledgeCardSuggestions) return [];
	if (!discardedSuggestions) return knowledgeCardSuggestions;
	let newSuggestions = [...knowledgeCardSuggestions];

	let discardedKeys = discardedSuggestions.map((_elem) =>
		getSuggestionKey(_elem)
	);

	newSuggestions = newSuggestions.filter(
		(_elem) => !discardedKeys.includes(getSuggestionKey(_elem))
	);
	return newSuggestions;
};

import React, { useState, useContext } from "react";

import {
	Form,
	Grid,
	Input,
	Checkbox,
	Button,
	Layout,
	Row,
	Col,
	Alert,
	Divider,
	Space,
	Typography,
	Card,
} from "antd";
import "./styles/verify.style.less";
import { AuthService } from "../auth/services/auth.service";
import { useNavigate } from "react-router";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { resendConfirmationCode } from "../auth/libs/cognito";
import Countdown from "antd/lib/statistic/Countdown";
import { Link } from "react-router-dom";

export const VerifyCode = () => {
	const [error, setError] = useState("");
	const [codeIsValid, setCodeIsValid] = useState(false);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [cognitorErrorMessage, setCognitorErrorMessage] = useState<
		string | null
	>(null);
	const authService: AuthService = new AuthService();
	const [emailError, setEmailError] = useState<string | null>(null);

	const [email, setEmail] = useState("");
	const [resend, setResend] = useState(false);

	// const [email, setEmail] = useState<string | null>(null);
	// const [code, setCode] = useState<any | null>(null);
	const navigate = useNavigate();

	const validateEmail = (e: any) => {
		setEmailError(null);
		setEmail(e.target.value);
		setIsSubmit(false);
	};

	const onFinish = async (values: any): Promise<void> => {
		setEmailError(null);
		if (!isSubmit) {
			setIsSubmit(true);
		}
		values.email = email;
		const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
		if (!pattern.test(email)) {
			return setEmailError("Please enter a valid email");
		}
		try {
			console.log(values);
			await authService.verifyCode(values.email, values.code);
			navigate("signin");
		} catch (err: any) {
			setCognitorErrorMessage(err.message);
		}
	};

	const handleResendCode = async (email: string) => {
		resendConfirmationCode(email);
	};

	const onResend = () => {
		console.log(resend);

		console.log("resend");
		setResend(true);
		console.log(resend);
	};

	const passwordResetClicked = async () => {
		navigate("/resetpassword");
	};

	return (
		<Row justify="center" className="signup-container">
			<Col span={12} className="left-column" style={{ width: "58%" }}>
				<img
					className="lettria-logo"
					alt=""
					src="/images/lettria.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/unlock-text.svg"
				/>
				<img
					className="landing"
					alt=""
					src="/images/icons/purposes.svg"
					style={{ marginLeft: "50%" }}
				/>
				<img
					alt=""
					src="/images/icons/clients.svg"
					style={{
						bottom: "48px",
						position: "absolute",
						// background:
						// 	"linear-gradient(0deg, #646E7F, #646E7F), url(1200px-Leroy_Merlin.png)",
						// backgroundBlendMode: "overlay, normal",
						// flex: "none",
						// order: "1",
						// flexGrow: "0",
					}}
				/>
			</Col>
			<Col span={12} className="right-column" style={{ padding: "80px" }}>
				<p className="no-card">
					<ArrowLeftOutlined
						style={{ color: "#6b4eb6", marginRight: "10px" }}
					/>
					<Link to="/signup" className="no-card-link">
						Back to create your account
					</Link>
				</p>
				<Typography.Title
					level={1}
					style={{
						margin: 0,
						wordBreak: "break-word",
					}}>
					Verify your account
				</Typography.Title>
				<Typography.Paragraph
					style={{
						width: " 430px",
						height: "88px",
						marginTop: "4px",
						marginBottom: "24px",
						wordBreak: "break-word",
					}}>
					We just sent a verification code to{" "}
					{/*[email@gmail.com].{" "}*/} your email
					<br />
					<br /> Check your inbox and enter the verification code
					below to verify your email address. The code will expire in{" "}
					<Countdown
						style={{
							display: "inline-block",
							width: "fit-content",
						}}
						value={Date.now() + 15 * 60 * 1000}
						// onChange={onChange}
					/>
				</Typography.Paragraph>
				<Form onFinish={onFinish} onChange={() => setIsSubmit(false)}>
					<Form.Item name="email">
						<Input
							// type="email"
							onChange={(e) => validateEmail(e)}
							prefix={
								<UserOutlined className="site-form-item-icon" />
							}
							placeholder="Email"
							className="inputs"
						/>
						{cognitorErrorMessage ? (
							<p style={{ color: "#ff4d4f" }}>
								{cognitorErrorMessage}
							</p>
						) : null}
						{emailError ? (
							<p style={{ color: "#ff4d4f" }}>{emailError}</p>
						) : null}
					</Form.Item>
					<Form.Item
						name="code"
						rules={[
							() => ({
								validator(_, value) {
									const pattern = new RegExp(/^[0-9]{6}$/i);
									if (!isSubmit) {
										return Promise.resolve();
									}

									if (pattern.test(value)) {
										// setCode(value);
										return Promise.resolve();
									} else {
										return Promise.reject(
											"Please enter a valid code (6 digits) or request a code again "
										);
									}
								},
							}),
						]}>
						<Input
							className="inputs"
							type="code"
							prefix={
								<UserOutlined className="site-form-item-icon" />
							}
							placeholder="6-digits code"
						/>
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="verify-form-button">
							Verify
						</Button>
					</Form.Item>
					I haven’t received my code yet.{" "}
					{resend ? (
						<>
							<a
								style={{
									color: "#6B4EB6",
									fontWeight: "bold",
								}}
								onClick={() => handleResendCode(email)}>
								Resend code
							</a>
						</>
					) : (
						<>
							Resend in{" "}
							<Countdown
								style={{ display: "inline-block" }}
								value={Date.now() + 10 * 1000}
								onFinish={onResend}
							/>
						</>
					)}
					{/* <Button
						type="primary"
						// htmlType="submit"
						className="verify-form-button"
						onClick={() => handleResendCode(email)}>
						Resend
					</Button> */}
				</Form>
			</Col>
		</Row>
	);
};

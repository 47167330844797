import { RequestService } from "services/request.service";

export class NotificationGroupApi extends RequestService {
	public acceptInvitationApi = async (invitationId: string) => {
		const result = await this.request("group/accept-invitation", "POST", {
			invitationId,
		});
		return result;
	};

	public refuseInvitationApi = async (invitationId: string) => {
		const result = await this.request("group/refuse-invitation", "POST", {
			invitationId,
		});
		return result;
	};
}
